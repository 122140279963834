import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';
import messages from './EvtTable.intl';

import ReactTable from 'react-table';

import Loading from '../Loading/Loading';
import Pagination from '../Pagination/Pagination';

import { Container } from 'reactstrap';
import Header from '../../components/Header/Header';

import FilterBuilder, {
    Operators,
    SortDirection,
} from '../../models/FilterBuilder';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import { URIDecode } from '../../utils/URIDecode';

const DEFAULT_PAGE = 0;
const DEFAULT_PAGES = 1;

export const buildFilterOr = (value, properties, pageSize) => {
    const params = new URLSearchParams(window.location.search);
    const page = params.get('page');
    let filters = [];

    if (!!properties) {
        properties.forEach((prop, index) => {
            const filter = {
                hideAddButton: false,
                property: prop.key,
                operator: Operators.like,
                value: value,
                type: '',
                enums: [],
                id: index,
            };

            if (typeof prop._map === 'function') {
                filter.value = prop._map(
                    (filter.value || '').trim().toLowerCase(),
                );
            }

            filters.push(filter);
        });
    }

    filters.push({
        operator: Operators.or,
    });

    const take = pageSize || 10;
    const skip = page * take;

    let fb = new FilterBuilder();

    if (filters) {
        filters.forEach(f => {
            fb = fb.filter(f.property, f.operator, f.value);
        });
    }

    fb = fb
        .filter('createdAt', Operators.sort, SortDirection.desc)
        .filter('', Operators.skip, skip)
        .filter('', Operators.take, take)
        .filter('', Operators.page, page);

    return fb.build();
};

export const calculateColumnWidth = (rows, accessor, headerText) => {
    const maxWidth = 150;
    const magicSpacing = 5;
    const cellLength = Math.max(
        ...rows.map(row => (`${row[accessor]}` || '').length),
        headerText.length,
    );
    return Math.min(maxWidth, cellLength * magicSpacing);
};

class EvtTable extends Component {
    page = new URLSearchParams(window.location.search).get('page');

    state = {
        page: Number(this.page) || DEFAULT_PAGE,
        pages: DEFAULT_PAGES,
        sorted: null,
    };

    componentDidMount() {
        this.updatePages();
    }

    componentDidUpdate(prevProps) {
        const { length, filters } = this.props;

        if (prevProps.length !== length) {
            this.updatePages();
        }

        if (prevProps.filters !== filters) {
            const pageFilter = filters[0]?.page || 0;
            this.setState(
                {
                    page: pageFilter || 0,
                },
                () => {
                    const filter = this.buildFilter();
                    this.props.handleStateChange &&
                        this.props.handleStateChange(URIDecode(filter));
                },
            );
        }
    }

    updatePages = () => {
        const { pageSize } = this.props;
        let { length } = this.props;

        const pages =
            length > pageSize ? Math.ceil(length / pageSize) : DEFAULT_PAGES;

        this.setState({
            pages,
            page: 0,
        });
    };

    buildFilter = () => {
        const { filters, pageSize } = this.props;
        const { page, sorted } = this.state;

        const skip = page * pageSize;
        const take = pageSize;

        let fb = new FilterBuilder();
        const hasSearch = filters?.find(f => f.operator === Operators.or);

        if (sorted) {
            fb = fb.filter(
                sorted.id,
                Operators.sort,
                sorted.desc ? SortDirection.desc : SortDirection.asc,
            );
        }

        fb = fb
            .filter('', Operators.skip, skip)
            .filter('', Operators.take, take)
            .filter('', Operators.page, page);

        if (filters && (!this.props.search || !hasSearch)) {
            filters.forEach(f => {
                fb = fb.filter(f.property, f.operator, f.value);
            });
        } else if (hasSearch) {
            const searchValue = filters[0].value;
            fb = fb.filter('', Operators.search, searchValue);
        }

        return fb.build();
    };

    handleFetchData = state => {
        let sorted = null;
        if (state.sorted.length > 0) {
            sorted = state.sorted[0];
        }

        this.setState(
            {
                page: state.page,
                sorted,
            },
            () => {
                const URL = window.location.search;
                const query = URL || this.buildFilter();
                this.props.handleStateChange &&
                    this.props.handleStateChange(URIDecode(query));
            },
        );
    };

    handleSetPage = page => {
        this.setState({ page }, () => {
            this.props.fetchPage &&
                this.props.handleStateChange(this.buildFilter());
        });
    };

    render() {
        const {
            intl,
            pageSize,
            loading,
            columns,
            data,
            defaultSorted,
            handleGetTrProps,
            countHeaderText,
            alignCountToLeft,
            tabInside,
            frontPagination,
            removePagination,
        } = this.props;
        const { page, pages } = this.state;
        const dataSlice =
            (data.length > 0 &&
                data.slice(page * pageSize, pageSize * (page + 1))) ||
            [];

        return (
            <ErrorBoundary>
                <Container fluid>
                    {countHeaderText && (
                        <Header
                            subtitle={
                                alignCountToLeft ? (
                                    <div style={{ textAlign: 'left' }}>
                                        {countHeaderText}
                                    </div>
                                ) : (
                                    countHeaderText
                                )
                            }
                        />
                    )}
                    <ReactTable
                        manual
                        loading={loading}
                        columns={columns.filter(column => !column.disabled)}
                        data={frontPagination ? dataSlice : data}
                        PaginationComponent={props =>
                            !removePagination && (
                                <Pagination
                                    {...props}
                                    onPageChange={this.handleSetPage}
                                />
                            )
                        }
                        page={page}
                        pages={pages}
                        noDataText={intl.formatMessage(messages.noDataText)}
                        minRows={0}
                        defaultPageSize={pageSize}
                        defaultSorted={defaultSorted}
                        className={
                            !tabInside
                                ? '-striped -highlight text-center'
                                : '-striped -highlight text-center tab-inside'
                        }
                        previousText={intl.formatMessage(
                            messages.previousButton,
                        )}
                        nextText={intl.formatMessage(messages.nextButtonText)}
                        loadingText={<Loading loading={true} />}
                        pageText={intl.formatMessage(messages.pageText)}
                        ofText={intl.formatMessage(messages.ofText)}
                        rowsText={intl.formatMessage(messages.rowsText)}
                        pageJumpText={intl.formatMessage(messages.pageJumpText)}
                        rowsSelectorText={intl.formatMessage(
                            messages.rowsSelectorText,
                        )}
                        getTrProps={handleGetTrProps}
                        onFetchData={this.handleFetchData}
                        title='Campo Indisponivel'
                    />
                </Container>
            </ErrorBoundary>
        );
    }
}

EvtTable.propTypes = {
    loading: PropTypes.bool,
    removePagination: PropTypes.bool,
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    length: PropTypes.number,
    filters: PropTypes.array,
    pageSize: PropTypes.number.isRequired,
    search: PropTypes.bool,
    handleStateChange: PropTypes.func.isRequired,
    handleGetTrProps: PropTypes.func,
};

export default injectIntl(EvtTable);
