import axios from 'axios';
import * as actionTypes from './actionTypes';

const getDetailsByKey = key => {
    const endpoints = {
        webservice: 'Webservice',
        webserviceError: 'WebserviceErrors',
        ocrProccess: 'OCRProcess',
        email: 'Email',
        portal: 'Portal',
        boleto: 'Boletos',
        attachedFiles: 'AttachedFiles',
        canceled: 'Canceled',
        pendingFile: 'PendingFiles',
    };

    return endpoints[key] || 'Webservice';
};

export const getHealthStatus = period => {
    return dispatch => {
        dispatch({
            type: actionTypes.GET_HEALTH_REQUEST,
        });

        axios
            .get('/HealthNFs', { params: { period } })
            .then(response => {
                dispatch({
                    type: actionTypes.GET_HEALTH_SUCCESS,
                    healthResponse: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.GET_HEALTH_FAILURE,
                    error,
                });
            });
    };
};

export const getHealthDetailsStatus = (period, key, ibgeCode) => {
    return dispatch => {
        dispatch({
            type: actionTypes.GET_HEALTH_DETAILS_REQUEST,
        });

        axios
            .get(`/HealthNFs/Details/${getDetailsByKey(key)}`, {
                params: { period, ibgeCode: ibgeCode || 0 },
            })
            .then(response => {
                dispatch({
                    type: actionTypes.GET_HEALTH_DETAILS_SUCCESS,
                    healthDetailsResponse: { filters: response.data, key },
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.GET_HEALTH_DETAILS_FAILURE,
                    error,
                });
            });
    };
};

export const getHealthDocumentsByKey = (
    period,
    key,
    ibgeCode,
    skip,
    take,
    sort,
) => {
    return dispatch => {
        dispatch({
            type: actionTypes.GET_HEALTH_DOCUMENTS_REQUEST,
        });

        axios
            .get(`/HealthNFs/Details/${getDetailsByKey(key)}`, {
                params: { period, ibgeCode: ibgeCode || 0, skip, take, sort },
            })
            .then(response => {
                dispatch({
                    type: actionTypes.GET_HEALTH_DOCUMENTS_SUCCESS,
                    healthDocuments: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.GET_HEALTH_DOCUMENTS_FAILURE,
                    error,
                });
            });
    };
};

export const setHealthTableProps = initialState => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_HEALTH_TABLE_PROPS,
            healthTableProps: initialState,
        });
    };
};

export const cleanHealthDetails = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.ClEAN_HEALTH_DETAILS,
        });
    };
};
