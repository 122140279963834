export const formatFilterForQuery = filters => {
    const filterStrings = filters.map(filter => {
        return `filter[${filter.property}]=${filter.operator}:${filter.value}`;
    });

    return filterStrings.join('&');
};

export const parseQueryString = queryString => {
    const query = queryString.startsWith('?')
        ? queryString.slice(1)
        : queryString;

    const pairs = query.split('&');

    const params = {};
    pairs.forEach(pair => {
        const [key, value] = pair.split('=');
        params[key] = isNaN(value) ? value : Number(value);
    });

    return params;
};
