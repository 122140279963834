import React from 'react';

const ArrowAscDesc = ({
    width = '16',
    height = '16',
    viewBox = '0 0 11 9',
    fill = 'none',
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox={viewBox}
            fill={fill}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M5.33545 6.01011C5.3359 5.94391 5.31333 5.87908 5.27063 5.82391C5.22793 5.76874 5.16703 5.72571 5.09569 5.70032C5.02435 5.67493 4.94581 5.66833 4.87007 5.68135C4.79434 5.69438 4.72484 5.72644 4.67045 5.77345L3.216 7.01011L3.216 1.05011C3.216 0.961707 3.17503 0.876923 3.1021 0.814411C3.02917 0.751899 2.93025 0.716779 2.82711 0.716779C2.72397 0.716779 2.62506 0.751899 2.55213 0.814411C2.4792 0.876923 2.43822 0.961707 2.43822 1.05011L2.43822 7.01011L0.987668 5.77345C0.951409 5.74259 0.908413 5.71815 0.861136 5.70153C0.813858 5.68491 0.763225 5.67644 0.712127 5.67659C0.661029 5.67675 0.610467 5.68553 0.563327 5.70243C0.516188 5.71934 0.473395 5.74403 0.437391 5.77511C0.401387 5.80619 0.372877 5.84305 0.353489 5.88357C0.334102 5.92409 0.324216 5.96749 0.324397 6.01129C0.324761 6.09975 0.366106 6.18445 0.439335 6.24678L2.82711 8.28345L5.21489 6.24678C5.25234 6.21619 5.28229 6.1795 5.30299 6.13886C5.32369 6.09822 5.33473 6.05445 5.33545 6.01011Z'
                fill='black'
            />
            <path
                d='M5.45178 2.75011C5.37803 2.81244 5.3362 2.89732 5.33547 2.98609C5.33474 3.07486 5.37518 3.16024 5.44789 3.22345C5.5206 3.28666 5.61964 3.32252 5.7232 3.32314C5.82676 3.32377 5.92637 3.28911 6.00011 3.22678L7.46234 1.98678V7.95011C7.46234 8.03852 7.50331 8.1233 7.57624 8.18582C7.64917 8.24833 7.74808 8.28345 7.85122 8.28345C7.95436 8.28345 8.05328 8.24833 8.12621 8.18582C8.19914 8.1233 8.24011 8.03852 8.24011 7.95011V1.98678L9.679 3.22678C9.71526 3.25764 9.75826 3.28208 9.80553 3.2987C9.85281 3.31531 9.90345 3.32379 9.95454 3.32363C10.0056 3.32348 10.0562 3.3147 10.1033 3.29779C10.1505 3.28089 10.1933 3.25619 10.2293 3.22511C10.2653 3.19403 10.2938 3.15718 10.3132 3.11666C10.3326 3.07613 10.3425 3.03273 10.3423 2.98893C10.3421 2.94514 10.3319 2.9018 10.3121 2.86139C10.2924 2.82099 10.2636 2.78431 10.2273 2.75345L7.85122 0.716779L5.45178 2.75011Z'
                fill='black'
            />
        </svg>
    );
};

export default ArrowAscDesc;
