import React from 'react';

import MapIcon from './MapIcon';
import UserIcon from './UserIcon';
import ProfileIcon from './ProfileIcon';
import LogIcon from './LogIcon';
import SettingsIcon from './SettingsIcon';
import LogoutIcon from './LogoutIcon';
import HomeIcon from './HomeIcon';
import ContractorIcon from './ContractorIcon';
import SupplierIcon from './SupplierIcon';
import OrderIcon from './OrderIcon';
import DocumentIcon from './DocumentIcon';
import NFeIcon from './NFeIcon';
import CTeIcon from './CTeIcon';
import ImportIcon from './ImportIcon';
import TransactionIcon from './TransactionIcon';
import CertificateIcon from './CertificateIcon';
import PendingFiles from './PendingFiles';
import NFSeIcon from './NFSeIcon';
import OtherIcon from './OtherIcon';
import HealthIcon from './HealthIcon';
import ArrowDesc from './ArrowDesc';
import ArrowAsc from './ArrowAsc';
import ArrowAscDesc from './ArrowAscDesc';
import LeftArrow from './LeftArrow';
import RightArrow from './RightArrow';
import TopArrow from './TopArrow';
import DownArrow from './DownArrow';
import Check from './Check';
import Export from './Export';

const Icon = props => {
    switch (props.name) {
        case 'home':
            return <HomeIcon {...props} />;
        case 'contractors':
            return <ContractorIcon {...props} />;
        case 'suppliers':
            return <SupplierIcon {...props} />;
        case 'orders':
            return <OrderIcon {...props} />;
        case 'documents':
            return <DocumentIcon {...props} />;
        case 'nfeDocument':
            return <NFeIcon {...props} />;
        case 'cteDocument':
            return <CTeIcon {...props} />;
        case 'nfseDocument':
            return <NFSeIcon {...props} />;
        case 'otherDocument':
            return <OtherIcon {...props} />;
        case 'import':
            return <ImportIcon {...props} />;
        case 'transactions':
            return <TransactionIcon {...props} />;
        case 'maps':
            return <MapIcon {...props} />;
        case 'users':
            return <UserIcon {...props} />;
        case 'profiles':
            return <ProfileIcon {...props} />;
        case 'logs':
            return <LogIcon {...props} />;
        case 'settings':
            return <SettingsIcon {...props} />;
        case 'logout':
            return <LogoutIcon {...props} />;
        case 'certificate':
            return <CertificateIcon {...props} />;
        case 'pendingFiles':
            return <PendingFiles {...props} />;
        case 'health':
            return <HealthIcon {...props} />;
        case 'arrowDesc':
            return <ArrowDesc {...props} />;
        case 'arrowAsc':
            return <ArrowAsc {...props} />;
        case 'arrowDescAsc':
            return <ArrowAscDesc {...props} />;
        case 'leftArrow':
            return <LeftArrow {...props} />;
        case 'rightArrow':
            return <RightArrow {...props} />;
        case 'topArrow':
            return <TopArrow {...props} />;
        case 'downArrow':
            return <DownArrow {...props} />;
        case 'check':
            return <Check {...props} />;
        case 'export':
            return <Export {...props} />;
        default:
            return;
    }
};

export default Icon;
