import * as actionTypes from '../actions/actionTypes';

const initialState = {
    healthResponse: null,
    loadingHealthStatus: false,

    healthDetailsResponse: null,
    loadingHealthDetails: false,

    healthDocuments: [],
    loadingHealthDocuments: false,

    healthTableProps: {},
    loadingHealthTableProps: false,
};

const getHealthRequest = (state, _action) => {
    return {
        ...state,
        loadingHealthStatus: true,
    };
};

const getHealthResponse = (state, { healthResponse, error }) => {
    const newState = error
        ? { ...state, error }
        : {
              ...state,
              healthResponse,
          };

    return {
        ...newState,
        loadingHealthStatus: false,
    };
};

const getHealthDetailsRequest = (state, _action) => {
    return {
        ...state,
        loadingHealthDetails: true,
    };
};

const getHealthDetailsResponse = (state, { healthDetailsResponse, error }) => {
    const newState = error
        ? { ...state, error }
        : {
              ...state,
              healthDetailsResponse,
          };

    return {
        ...newState,
        loadingHealthDetails: false,
    };
};

const getHealthDocumentsRequest = (state, _action) => {
    return {
        ...state,
        loadingHealthDocuments: true,
    };
};

const getHealthDocuments = (state, { healthDocuments, error }) => {
    const newState = error
        ? { ...state, error }
        : {
              ...state,
              healthDocuments,
          };

    return {
        ...newState,
        loadingHealthDocuments: false,
    };
};

const setHealthTablePropsResponse = (state, { healthTableProps }) => {
    return {
        ...state,
        healthTableProps,
    };
};

const cleanHealthDetails = (state, action) => {
    return {
        ...state,
        healthDetailsResponse: null,
        loadingHealthDetails: false,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_HEALTH_REQUEST:
            return getHealthRequest(state, action);

        case actionTypes.GET_HEALTH_SUCCESS:
        case actionTypes.GET_HEALTH_FAILURE:
            return getHealthResponse(state, action);

        case actionTypes.GET_HEALTH_DETAILS_REQUEST:
            return getHealthDetailsRequest(state, action);

        case actionTypes.GET_HEALTH_DETAILS_SUCCESS:
        case actionTypes.GET_HEALTH_DETAILS_FAILURE:
            return getHealthDetailsResponse(state, action);

        case actionTypes.GET_HEALTH_DOCUMENTS_REQUEST:
            return getHealthDocumentsRequest(state, action);

        case actionTypes.GET_HEALTH_DOCUMENTS_SUCCESS:
        case actionTypes.GET_HEALTH_DOCUMENTS_FAILURE:
            return getHealthDocuments(state, action);

        case actionTypes.SET_HEALTH_TABLE_PROPS:
            return setHealthTablePropsResponse(state, action);

        case actionTypes.ClEAN_HEALTH_DETAILS:
            return cleanHealthDetails(state, action);

        default:
            return state;
    }
};

export default reducer;
