import { filterPropertyTypes, healthPeriod } from '../../../../utils/enums';

export const getCitiesByData = (data = []) => {
    const cities = data.map(x => ({ label: x.city, value: x.city }));
    return cities;
};

export const getFiltersProperties = data => [
    {
        label: 'Cidade',
        type: filterPropertyTypes.ENUM,
        property: 'city',
        options: getCitiesByData(data),
    },
];

export const periodOpts = [
    { label: 'Dia', value: healthPeriod.day },
    { label: 'Semana', value: healthPeriod.week },
    { label: 'Mês', value: healthPeriod.month },
    { label: 'Últimos 30 dias', value: healthPeriod.lastThirtyDays },
    { label: 'Último mês', value: healthPeriod.lastMonth },
];

export const healthInitialState = {
    variant: 'ui-table',
    columnsDisabled: [],
    noRegisterMessages: 'Não há notas registradas nesse período.',
    sorting: {
        id: 'city',
        desc: true,
    },
    pagination: {
        pageSize: 10,
    },
};

export const columns = (onCellClick, onCellModalClick) => [
    { id: 'city', header: 'Cidade' },
    { id: 'ibge', header: 'IBGE' },
    { id: 'webserviceConsult', header: 'Consultas Webservice' },
    { id: 'ocrProccess', header: 'Processamento OCR' },
    { id: 'webservice', header: 'WebService', onCellClick },
    { id: 'email', header: 'E-mail', onCellClick },
    { id: 'portal', header: 'Portal', onCellClick },
    { id: 'boleto', header: 'Boletos', onCellClick: onCellModalClick },
    {
        id: 'attachedFiles',
        header: 'Arquivos Anexados',
        onCellClick: onCellModalClick,
    },
    { id: 'canceled', header: 'Canceladas', onCellClick },
];

export const additionalRows = (
    totalRow,
    onCellClickInTotal,
    onCellClick,
    onCellModalClick,
) => [
    {
        city: 'Totais',
        ibge: '',
        webserviceConsult: totalRow?.webserviceConsult || '',
        ocrProccess: totalRow?.ocrProccess || '',
        webservice: totalRow?.webservice || '',
        email: totalRow?.email || '',
        portal: totalRow?.portal || '',
        boleto: totalRow?.boleto || '',
        attachedFiles: totalRow?.attachedFiles || '',
        canceled: totalRow?.canceled || '',
        allFields: true,
        clickHandles: [
            { id: 'webservice', onCellClick },
            { id: 'email', onCellClick },
            { id: 'portal', onCellClick },
            { id: 'boleto', onCellClick: onCellModalClick },
            { id: 'attachedFiles', onCellClick: onCellModalClick },
            { id: 'canceled', onCellClick },
        ],
    },
    {
        city: 'Documentos pendentes OCR',
        ibge: totalRow?.ocrPending || '-',
        clickHandles: [
            {
                id: 'ibge',
                onCellClick: () => onCellClickInTotal('pendingFile'),
            },
        ],
    },
    {
        city: 'Erros do Webservice',
        ibge: totalRow?.webserviceError || '-',
        clickHandles: [
            {
                id: 'ibge',
                onCellClick: () => onCellClickInTotal('webserviceError'),
            },
        ],
    },
    {
        city: 'Documentos processados no período',
        ibge: totalRow?.documentsProcessed || '-',
    },
];
