export const calcColumnWidth = (rows, key, maxW, minW, header) => {
    const headerLength = header?.length;
    const cellLength = Math.max(
        ...rows.map(row => (`${row[key]}` || '').length),
    );
    const maxLength = Math.max(headerLength, cellLength);

    const baseWidth = (maxLength + 6) * 8;
    const minColumnW = minW ? Math.max(baseWidth, minW) : baseWidth;
    const maxColumnW = maxW ? Math.max(baseWidth, maxW) : baseWidth;

    return { minColumnW, maxColumnW };
};
