import React from 'react';

const ArrowDesc = ({
    width = '16',
    height = '16',
    viewBox = '0 0 6 9',
    fill = 'none',
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox={viewBox}
            fill={fill}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M5.33545 6.01011C5.3359 5.94391 5.31333 5.87908 5.27063 5.82391C5.22793 5.76874 5.16703 5.72571 5.09569 5.70032C5.02435 5.67493 4.94581 5.66833 4.87007 5.68135C4.79434 5.69438 4.72484 5.72644 4.67045 5.77345L3.216 7.01011L3.216 1.05011C3.216 0.961707 3.17503 0.876923 3.1021 0.814411C3.02917 0.751899 2.93025 0.716779 2.82711 0.716779C2.72397 0.716779 2.62506 0.751899 2.55213 0.814411C2.4792 0.876923 2.43822 0.961707 2.43822 1.05011L2.43822 7.01011L0.987668 5.77345C0.951409 5.74259 0.908413 5.71815 0.861136 5.70153C0.813858 5.68491 0.763225 5.67644 0.712127 5.67659C0.661029 5.67675 0.610467 5.68553 0.563327 5.70243C0.516188 5.71934 0.473395 5.74403 0.437391 5.77511C0.401387 5.80619 0.372877 5.84305 0.353489 5.88357C0.334102 5.92409 0.324216 5.96749 0.324397 6.01129C0.324761 6.09975 0.366106 6.18445 0.439335 6.24678L2.82711 8.28345L5.21489 6.24678C5.25234 6.21619 5.28229 6.1795 5.30299 6.13886C5.32369 6.09822 5.33473 6.05445 5.33545 6.01011Z'
                fill='black'
            />
        </svg>
    );
};

export default ArrowDesc;
