export const getTableStyles = customTableStyles => ({
    className: '',
    style: {},
    children: {
        cell: { className: '', style: {} },
        row: { className: '', style: {} },
        header: {
            className: '',
            style: {},
            children: {
                cell: { className: '', style: {} },
                row: { className: '', style: {} },
            },
        },
        body: {
            className: '',
            style: {},
            children: {
                cell: { className: '', style: {} },
                row: { className: '', style: {} },
            },
        },
        pagination: {
            className: '',
            style: {},
            children: {
                button: { className: '', style: {} },
                sizeOptions: { className: '', style: {} },
            },
        },
    },
    ...customTableStyles,
});

export const getInitialState = initialStateTable => ({
    variant: 'ui-table',
    columnOrder: [],
    columnsDisabled: ['id'],
    filters: [],
    expanded: true,
    sorting: {
        id: '',
        desc: true,
    },
    pageSizesOptions: [5, 10, 20, 25, 50],
    onSortingChange: () => {},
    onFiltersChange: () => {},
    onPaginationChange: () => {},
    onFilterChange: () => {},
    onFetchData: () => {},
    onRowClick: null,
    ...initialStateTable,
    pagination: {
        pageIndex: 0,
        pageSize: 10,
        ...initialStateTable.pagination,
    },
});
