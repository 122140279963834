import React, { useContext } from 'react';
import { TableContext } from '..';
import UI from '../..';

function Filter({ onFiltersChange }) {
    const { filtersProperties, setFilters } = useContext(TableContext);

    const onChangeFilters = (selectedOptions, filterKey, option) => {
        setFilters(prevFilters => {
            const updatedFilters = {
                ...prevFilters,
                [filterKey]: {
                    key: filterKey,
                    selectedOptions,
                },
            };
            return updatedFilters;
        });
        onFiltersChange(selectedOptions, filterKey, option);
    };

    return (
        <div>
            {filtersProperties?.map(filterProp => {
                return (
                    <>
                        <UI.FilterDropdown
                            options={filterProp.options || []}
                            label={filterProp.label}
                            filterKey={filterProp.property}
                            onChange={onChangeFilters}
                        />
                    </>
                );
            })}
        </div>
    );
}

export default Filter;
