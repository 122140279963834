import React from 'react';

function Cell(props) {
    const { children, className, style, isHeader = false, onClick } = props;
    const Component = isHeader ? 'th' : 'td';

    return (
        <Component
            className={`ui-table-cell ${isHeader &&
                'cell-header'} ${className}`}
            style={Object.assign(
                {
                    display: 'flex',
                    alignItems: 'center',
                },
                style,
            )}
            onClick={onClick}
        >
            {children}
        </Component>
    );
}

export default Cell;
