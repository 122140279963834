import React, { Component } from 'react';

import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';

import { injectIntl } from 'react-intl';
import messages from './DocumentListPage.intl';

import { toast } from 'react-toastify';
import { Container } from 'reactstrap';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { AuthContext } from '../../context/AuthContext';

import NFSeList from './pages/NFSeList';

import ModalSupplier from '../../components/ModalSupplier/ModalSupplier';
import ModalMap from '../../components/ModalMap/ModalMap';
import ModalReportXml from './components/ModalReportXml';
import { URIDecode } from '../../utils/URIDecode';

export const formatFilters = query => {
    let finalValue = [];
    const arrQuery = query.replace('?', '').split('&');
    const filters = arrQuery.filter(e => e.includes('filter'));
    const arrPage = arrQuery.filter(e => e.includes('page'));
    const page = arrPage.length > 0 && arrPage[0].replace('page=', '');

    filters.forEach(filters => {
        const [property, operator, value] = URIDecode(filters)
            .replaceAll('filter[', '')
            .replace(':', ']=')
            .split(']=');
        finalValue.push({
            property,
            operator,
            value: value?.replaceAll('+', ' '),
            page: Number(page),
        });
    });

    const searchValue = arrQuery.filter(e => e.includes('search='));
    if (searchValue.length > 0) {
        const value = searchValue[0]
            ?.replace('search=', '')
            .replaceAll('+', ' ');
        finalValue.push({ value, page: Number(page) });
    }

    return finalValue;
};
class DocumentListPage extends Component {
    static contextType = AuthContext;

    filterFields = [];

    state = {
        activeTab: null,
        selectedSupplierId: '',
        showSupplierPicker: false,
        showMapPicker: false,
        showContractor: true,
        orderId: null,
        tab: undefined,
    };

    componentDidMount() {
        const { orderId, supplierId } = this.props.location.state || {};
        const { tabIndex, query, history } = this.props;
        const index =
            tabIndex ||
            new URLSearchParams(window.location.search).get('index') ||
            0;

        this.setState({
            activeTab: Number(index),
        });

        this.props.ontInitCategories();

        if (orderId) {
            this.props.onInitSupplierMaps(supplierId, '?skip=0&take=500');

            this.setState({
                selectedSupplierId: supplierId,
                orderId: orderId,
            });
        }

        if (query) {
            history.push('documents' + query);
        }
    }

    componentWillUnmount() {
        this.props.onCleanHealthDetails();
    }

    componentDidUpdate(prevProps) {
        const { maps, documentsReport } = this.props;
        const { selectedSupplierId } = this.state;

        if (!!maps && maps !== prevProps.maps) {
            const mapsCount = maps?.length;

            if (mapsCount === 0) {
                this.props.history.push({
                    pathname: `/documents/new`,
                    state: {
                        supplierId: selectedSupplierId,
                        mapId: null,
                        origin: origin,
                        hideCrop,
                    },
                });
                this.setState({
                    showMapPicker: false,
                    showSupplierPicker: false,
                });
            } else if (mapsCount === 1) {
                var _maps = this.props.maps;
                var mapFull =
                    _maps && _maps.find(map => map.mapId === maps[0].mapId);
                var origin = 'WEB_UI';
                var hideCrop = mapFull && mapFull.hideCrop;

                this.props.history.push({
                    pathname: `/documents/new`,
                    state: {
                        supplierId: selectedSupplierId,
                        mapId: maps[0].mapId,
                        origin: origin,
                        hideCrop,
                    },
                });
                this.setState({
                    showMapPicker: false,
                    showSupplierPicker: false,
                });
            } else {
                this.setState({
                    showMapPicker: true,
                    showSupplierPicker: false,
                });
            }
        }

        if (documentsReport !== prevProps.documentsReport) {
            const separator = ';';
            let data = '';

            if (documentsReport.length > 0) {
                for (var document of documentsReport) {
                    delete document.form;
                    delete document.currentStep;
                }

                Object.keys(documentsReport[0]).forEach(title => {
                    if (title === 'steps') {
                        data += 'stepOrder' + separator;
                        data += 'stepStatus' + separator;
                        data += 'stepName' + separator;
                        data += 'stepCreatedAt' + separator;
                    } else {
                        data += title + separator;
                    }
                });

                data += '\n';

                documentsReport.forEach(doc => {
                    doc.steps.forEach(step => {
                        Object.keys(doc).forEach(column => {
                            if (column === 'steps') {
                                data += `${step.order}${separator}`;
                                data += `${step.status}${separator}`;
                                data += `${step.text}${separator}`;
                                data += `${step.createdAt}${separator}`;
                            } else {
                                data += doc[column] + separator;
                            }
                        });

                        data += '\n';
                    });
                });

                const pom = document.createElement('a');
                pom.setAttribute(
                    'href',
                    'data:application/octet-stream,' + encodeURIComponent(data),
                );
                pom.setAttribute('download', 'data.csv');
                if (document.createEvent) {
                    var event = document.createEvent('MouseEvents');
                    event.initEvent('click', true, true);
                    pom.dispatchEvent(event);
                } else {
                    pom.click();
                }
            }
        }
    }

    handleTabClick = (e, index) => {
        if (this.state.activeTab !== index) {
            this.setState({
                activeTab: index,
            });
        }

        this.props.history.push(
            'documents?' +
                `sort=-createdAt&skip=0&take=10&page=0&index=${index}`,
        );
    };

    handleNewDocumentButtonClick = () => {
        const { hasClaim } = this.context;

        if (hasClaim('role-level-full') || hasClaim('role-level-contractor')) {
            this.setState({
                showSupplierPicker: !this.state.showSupplierPicker,
            });

            this.props.getActiveTab(this.state.activeTab);
        } else if (hasClaim('role-level-supplier')) {
            const supplierId = this.context.supplierId;
            this.props.onInitSupplierMaps(supplierId, '?skip=0&take=500');

            this.setState({
                selectedSupplierId: supplierId,
            });

            this.props.getActiveTab(this.state.activeTab);
        }
    };

    handleMapPickerToggle = () => {
        this.setState({
            showMapPicker: !this.state.showMapPicker,
        });
    };

    handleSupplierPickerToggle = () => {
        this.setState({
            showSupplierPicker: !this.state.showSupplierPicker,
        });
    };

    handleSupplierPickerSubmit = (e, values) => {
        const supplierId = values.supplierId || '';

        this.props.onInitSupplierMaps(supplierId, '?skip=0&take=500');
        this.props.onInitSupplierOrdersAvailable(supplierId, '?skip=0&take=10');

        this.setState({
            selectedSupplierId: supplierId,
        });
    };

    handleGetTrProps = (state, rowInfo, column, instance) => {
        return {
            style: {
                cursor: 'pointer',
            },
            onClick: e =>
                this.props.updateActiveTab(
                    rowInfo.original.id,
                    this.state.activeTab,
                ),
        };
    };

    mapCategoriesArray = categoriesArray => {
        if (!categoriesArray) return;
        const map = categoriesArray.map(category => {
            return {
                key: category.code,
                value: category.name,
            };
        });
        return map;
    };

    render() {
        const {
            activeTab,
            showMapPicker,
            showSupplierPicker,
            showContractor,
            orderId,
            selectedSupplierId,
            tab,
        } = this.state;
        const { intl } = this.props;
        const { hasClaim } = this.context;

        const mustViewNFSe = hasClaim('nfse-document-view');

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/documents',
                            name: intl.formatMessage(messages.title),
                            active: true,
                        },
                    ]}
                />
                <section className='content-middle'>
                    {mustViewNFSe && (
                        <NFSeList
                            mapCategoriesArray={this.mapCategoriesArray}
                            handleGetTrProps={this.handleGetTrProps}
                            handleNewDocumentButtonClick={
                                this.handleNewDocumentButtonClick
                            }
                            activeTab={activeTab}
                            tab={tab}
                            formatFilters={formatFilters}
                        />
                    )}
                </section>
                <ModalSupplier
                    handleSupplierPickerToggle={this.handleSupplierPickerToggle}
                    handleSupplierPickerSubmit={this.handleSupplierPickerSubmit}
                    showSupplierPicker={showSupplierPicker}
                    showContractor={showContractor}
                    selectedSupplier={{}}
                    selectedContractor={{}}
                />
                <ModalMap
                    handleMapPickerToggle={this.handleMapPickerToggle}
                    handleCleanSupplierPicker={() =>
                        this.setState({
                            selectedSupplierId: '',
                        })
                    }
                    showMapPicker={showMapPicker}
                    selectedSupplierId={selectedSupplierId}
                    pathname='documents'
                />
                <ModalReportXml />
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        maps: state.supplierReducer.maps,
        loadingMaps: state.supplierReducer.loadingMaps,
        documentsReport: state.documentReducer.documentsReport,
        tabIndex: state.documentReducer.tabIndex,
        query: state.documentReducer.query,
        supplierOrders: state.supplierReducer.supplierOrders,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        ontInitCategories: () => dispatch(actions.initCategories()),
        updateFilterDocumentsType: documentType =>
            dispatch(actions.updateFilterDocumentsType(documentType)),
        getActiveTab: tabIndex => dispatch(actions.getActiveTab(tabIndex)),
        updateActiveTab: (documentId, tabIndex) =>
            dispatch(actions.updateActiveTab(documentId, tabIndex)),

        //Modals
        onInitSupplierMaps: (supplierId, filter) =>
            dispatch(actions.initSupplierMaps(supplierId, filter)),

        onInitSupplierOrdersAvailable: (supplierId, filter) =>
            dispatch(actions.initSupplierOrdersAvailable(supplierId, filter)),
        onCleanHealthDetails: (supplierId, filter) =>
            dispatch(actions.cleanHealthDetails(supplierId, filter)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(DocumentListPage),
);
