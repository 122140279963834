import React, { useEffect, useState } from 'react';
import { sortedDataById } from '../../../utils/arrayUtils';
import Icon from '../../../assets/icons/Index';

const UIFilterDropdown = props => {
    const {
        variant = 'ui-filter-dropdown',
        label,
        options,
        onChange,
        filterKey,
    } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState([
        options.map(option => option.value),
    ]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        if (options.length > 0) {
            selectAllFilters();
        }
    }, [options]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleFilterChange = value => {
        const newOptions = selectedFilters.includes(value)
            ? selectedFilters.filter(item => item !== value)
            : [...selectedFilters, value];
        setSelectedFilters(newOptions);
        onChange(newOptions, filterKey, value);
    };

    const selectAllFilters = () => {
        const newOptions = options.map(option => option.value);
        setSelectedFilters(newOptions);
        onChange(newOptions, filterKey);
    };

    const clearFilters = () => {
        setSelectedFilters([]);
        onChange([], filterKey);
    };

    const onClickToSelectAll = () => {
        if (selectedFilters?.length === 0) selectAllFilters();
        else clearFilters();
    };

    const filteredOptions = options.filter(option =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase()),
    );

    const sortedOptions = sortedDataById(filteredOptions, 'label', true).map(
        x => ({
            label: x.label.toUpperCase(),
            value: x.value,
        }),
    );

    const clearFiltersText =
        selectedFilters?.length === 0 ? 'Selecionar tudo' : 'Limpar seleção';

    return (
        <div className={`${variant}`}>
            <button onClick={toggleDropdown} className={`${variant}-button`}>
                <span>{label}</span>
                {isOpen ? <Icon name='topArrow' /> : <Icon name='downArrow' />}
            </button>

            {isOpen && (
                <div className={`${variant}-content`}>
                    <div className={`${variant}-header`}>
                        <input
                            type='text'
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                            placeholder='Pesquisar...'
                            className={`${variant}-search`}
                        />
                        <span
                            onClick={onClickToSelectAll}
                            className={`${variant}-clear-filters`}
                        >
                            {clearFiltersText}
                        </span>
                    </div>

                    <div className={`${variant}-options`}>
                        {sortedOptions.map(option => (
                            <div
                                key={option.value}
                                className={`${variant}-option`}
                                onClick={() => handleFilterChange(option.value)}
                            >
                                <span>{option.label}</span>
                                {selectedFilters.includes(option.value) ? (
                                    <Icon name='check' />
                                ) : (
                                    <div style={{ width: 20, height: 20 }} />
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default UIFilterDropdown;
