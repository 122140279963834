import React from "react";
import { injectIntl } from "react-intl";
import ErrorWidget from '../../../../components/ErrorBoundary/ErrorWidget';

import messages from "./MonthlyConsumptionCounterWidget.intl";

const MonthlyConsumptionCounterWidget = props => {
	const { intl } = props;
	let value = props.data ? props.data : 0;
	let cssPie = "c100 p" + value;

	return (
		<ErrorWidget>
			<React.Fragment>
				<div className={cssPie}>
					<span>{value}%</span>
					<div className="slice">
						<div className="bar"></div>
						<div className="fill"></div>
					</div>
				</div>
				<p className='text-center'>{intl.formatMessage(messages.monthlyConsumptionCounter)}</p>
			</React.Fragment>
		</ErrorWidget>
	);
}


export default injectIntl(MonthlyConsumptionCounterWidget);
