import React, { useContext, useEffect, useState } from 'react';
import { TableContext } from '../';
import UISelect from '../../Select';

function PageSizeSection() {
    const {
        data,
        count,
        pagination,
        setPagination,
        initialState,
        tableStyles,
    } = useContext(TableContext);

    const { pageSize, pageIndex } = pagination || {};

    const sizeOptions = initialState?.pageSizesOptions?.map(item => ({
        value: item.toString(),
        label: item.toString(),
    }));

    const paginationSizeStyles =
        tableStyles?.children.pagination?.children.sizeOptions;

    const handleSelectSize = pageSizeSelected => {
        setPagination(prev => ({ ...prev, pageSize: pageSizeSelected.value }));
    };

    const initialResults = pageIndex * pageSize + 1;
    const lastResults = (pageIndex + 1) * pageSize;
    const totalResults = count || data.length;

    return (
        <div
            className={`pagination-size-options ${
                paginationSizeStyles?.className
            }`}
            style={Object.assign(
                {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 4,
                },
                paginationSizeStyles?.style,
            )}
        >
            <span className='pagination-size-text'>Linhas por página:</span>
            <UISelect
                className='pagination-size-select'
                options={sizeOptions}
                value={{
                    label: pageSize || '10',
                    value: pageSize || '10',
                }}
                noEmptySelect
                onChange={handleSelectSize}
            />
            <span className='pagination-size-counter'>{`${initialResults}-${
                lastResults > totalResults ? totalResults : lastResults
            } de ${totalResults} resultados`}</span>
        </div>
    );
}

export default PageSizeSection;
