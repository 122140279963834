import React, { useContext } from 'react';
import { TableContext } from '../';
import DropdownListButton from '../../../DropdownListButton/DropdownListButton';
import Icon from '../../../../assets/icons/Index';

function CustomColumnsButton(props) {
    const { toggleColumn } = props;
    const { columns, variant, columnsDisabled } = useContext(TableContext);

    return (
        <DropdownListButton
            className={`${variant}-dropdown-customize-column`}
            toggleItem={false}
            items={[
                ...columns.map(col => ({
                    name: col.header || col.id,
                    onClick: () => toggleColumn(col.id),
                    component: () => {
                        const isDisabled = columnsDisabled?.find(
                            x => x === col.id,
                        );
                        return (
                            <div className={`customize-dropdown-item`}>
                                {!isDisabled ? (
                                    <Icon name='check' />
                                ) : (
                                    <div style={{ width: 20, height: 20 }} />
                                )}
                                <span>{col.header || col.id}</span>
                            </div>
                        );
                    },
                })),
            ]}
        >
            Customizar Colunas
        </DropdownListButton>
    );
}

export default CustomColumnsButton;
