import React, { useContext, useState } from 'react';
import { injectIntl } from 'react-intl';

import * as actions from '../../store/actions/index';
import messages from './DocumentCommentsModal.intl';

import EvtTable from '../EvtTable/EvtTable';
import { Col, FormGroup, Row } from 'reactstrap';
import { connect } from 'react-redux';
import {
    columnsDocumentComments,
    commentsProperties
} from './components/Columns';
import Modal from '../Modal/Modal';
import Loading from '../Loading/Loading';
import EvtFilter from '../EvtFilter/EvtFilter';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { AuthContext } from '../../context/AuthContext';
import { toast } from 'react-toastify';

const baseCommentState = {
    comment: null,
    emailsToNotify: null
};

const defaultQueryAfterPost = "?sort=-createdAt&skip=0&take=10";

const DocumentCommentsModal = props => {
    const {
        intl,
        showAssociatedDocModal,
        handleToggleAssociatedDocModal,
        loadingModal,
        selectedDocument,
        documentComments,
        documentCommentsCount,
        loadingDocumentComments,
        pushUrl,
        docType,
        queryId,
    } = props;

    const documentId = selectedDocument?.documentId;

    const handleEvTableCommentsStateChange = query => {
        props.onInitDocumentComments(documentId, query);
    };

    const authContext = useContext(AuthContext);

    const canAddCommentsButtom = authContext.hasClaim(
        'document-comment-add',
    );

    const formRef = React.createRef();
    const [commentState, setCommentState] = useState(baseCommentState);

    const handleCommentValueChange = (e) => {
        const { name, value } = e.target;
        setCommentState({
            ...commentState,
            [name]: value
        });
    }

    const validateCommentFields = () => {
        if (!commentState.comment)
            return { required: { value: true } };

        return { required: { value: false } };
    };

    const [filters, setFilters] = useState([]);
    const [showNewCommentModal, setNewCommentModalState] = useState(false);

    const handleSearchBarChange = filtersParams => setFilters(filtersParams);

    const handleNewCommentButtonModal = () => {
        setNewCommentModalState(!showNewCommentModal)
    };

    const resetModalAction = () => setCommentState(baseCommentState);

    return (
        <Modal
            title={intl.formatMessage(messages.documentComments)}
            isOpen={showAssociatedDocModal}
            handleToggle={handleToggleAssociatedDocModal}
            size={'xxl'}
            modalBodyClassName={'list-modal'}
        >
            <Loading loading={loadingModal} />
            <header className='table-screen'>
                {canAddCommentsButtom && (
                    <section
                        className='btns-topo'
                        style={{
                            justifyContent: 'flex-start'
                        }}
                    >
                        <button
                            type='button'
                            disabled={false}
                            className='btn-submit'
                            onClick={(_) => handleNewCommentButtonModal()}
                        >
                            {intl.formatMessage(
                                messages.addButton,
                            )}
                        </button>
                    </section>
                )}
                <section
                    className='btns-topo'
                    style={{
                        justifyContent: 'flex-end',
                        width: '100%',
                    }}
                >
                    <div className='btns-topo btns-float-right'>
                        <EvtFilter
                            properties={commentsProperties(intl)}
                            handleFiltersChange={handleSearchBarChange}
                            loading={loadingDocumentComments}
                        />
                    </div>
                </section>
            </header>
            <section>
                <EvtTable
                    columns={columnsDocumentComments(
                        documentComments,
                        intl,
                        docType,
                    )}
                    filters={filters}
                    data={documentComments}
                    length={documentCommentsCount}
                    pageSize={10}
                    handleStateChange={handleEvTableCommentsStateChange}
                    loading={loadingDocumentComments}
                    defaultSorted={[
                        {
                            id: 'createdAt',
                            desc: true,
                        },
                    ]}
                    countHeaderText={intl.formatMessage(messages.subtitle, {
                        length: documentCommentsCount,
                    })}
                    fetchPage
                />
            </section>
            <AvForm
                ref={formRef}
                onValidSubmit={() => {
                    if (!commentState.comment)
                        return toast.error(
                            intl.formatMessage(messages.errorMessage),
                        );

                    handleNewCommentButtonModal();
                    props.onAddNewComment(documentId, commentState).then(() => {
                        props.onInitDocumentComments(documentId, defaultQueryAfterPost);
                        resetModalAction();
                    });
                }}
            >
                <Modal
                    title={intl.formatMessage(
                        messages.comment,
                    )}
                    isOpen={showNewCommentModal}
                    handleToggle={handleNewCommentButtonModal}
                    hasFooter
                    toggleSecondButton={() => {
                        if (formRef.current)
                            formRef.current.handleSubmit();
                    }}
                    firstButtonClass='delete'
                    secondButtonClass='btn-submit'
                    firstButtonText={intl.formatMessage(
                        messages.closeButton,
                    )}
                    secondButtonText={intl.formatMessage(
                        messages.submitButton,
                    )}
                    toggleFirstButton={handleNewCommentButtonModal}
                >
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <AvField
                                    label={intl.formatMessage(messages.commentLabel)}
                                    type='textarea'
                                    style={{
                                        height: '200px',
                                        width: '400px',
                                    }}
                                    name='comment'
                                    value={commentState.comment}
                                    onChange={handleCommentValueChange}
                                    id='commentField'
                                    validate={validateCommentFields()}
                                    errorMessage={intl.formatMessage(messages.errorMessage)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <AvField
                                    label={intl.formatMessage(messages.emailsToNotify)}
                                    type='text'
                                    name='emailsToNotify'
                                    id='emails'
                                    value={commentState.emailsToNotify}
                                    onChange={handleCommentValueChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Modal>
            </AvForm>
        </Modal>
    );
};

const mapStateToProps = state => {
    return {
        documentComments: state.documentReducer.documentComments || [],
        loadingDocumentComments: state.documentReducer.loadingDocumentComments,
        documentCommentsCount:
            state.documentReducer.documentCommentsCount
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitDocumentComments: (documentId, filter) =>
            dispatch(
                actions.initDocumentComments(documentId, filter),
            ),
        onAddNewComment: (documentId, payload) =>
            dispatch(
                actions.createComment(documentId, payload),
            ),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(DocumentCommentsModal),
);
