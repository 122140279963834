import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';

import { injectIntl } from 'react-intl';
import messages from './SuppliersCounterWidget.intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthContext } from '../../../../context/AuthContext';
import ErrorWidget from '../../../../components/ErrorBoundary/ErrorWidget';

function SuppliersCounterWidget(props) {
    const context = useContext(AuthContext);

    const { intl } = props;
    const clickable = context.hasClaim('supplier-view');

    function handleClick() {
        if (!props.readOnly && clickable) {
            props.history.push('/suppliers');
        }
    }

    return (
        <ErrorWidget>
            <div onClick={handleClick} className={clickable ? 'clickable' : ''}>
                    <FontAwesomeIcon icon='id-badge' className='mr-5 c01' size='2x' />
                <div>
                    <ErrorWidget>
                        <h1>{props ? props.data : 0}</h1>
                        <p>{intl.formatMessage(messages.suppliersCounter)}</p>
                    </ErrorWidget>
                </div>
            </div>
        </ErrorWidget>
    );  
}

export default injectIntl(withRouter(SuppliersCounterWidget));
