import React from 'react';

import messages from '../DocumentCommentsModal.intl';
import { calculateColumnWidth } from '../../EvtTable/EvtTable';
import { filterPropertyTypes } from '../../../utils/enums';

export const commentsProperties = intl => [
    {
        key: 'comment',
        value: intl.formatMessage(messages.comment),
        type: filterPropertyTypes.TEXT,
    },
    {
        key: 'createdBy',
        value: intl.formatMessage(messages.createdBy),
        type: filterPropertyTypes.TEXT,
    },
    {
        key: 'emailsToNotify',
        value: intl.formatMessage(messages.emailsToNotify),
        type: filterPropertyTypes.TEXT,
    },
];

export const columnsDocumentComments = (
    documentComments,
    intl
) => [
    {
        Header: () => (
            <div className='header-table-title'>
                <span>
                    {intl.formatMessage(messages.comment, {
                        entity: '',
                    })}
                </span>
            </div>
        ),
        accessor: 'comment',
        minWidth: calculateColumnWidth(
            documentComments,
            'comment',
            intl.formatMessage(messages.comment, {
                entity: '',
            }),
        ),
        minWidth: calculateColumnWidth(
            documentComments,
            'comment',
            intl.formatMessage(messages.comment, {
                entity: '',
            }),
        ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.createdBy)}</span>
            </div>
        ),
        accessor: 'createdBy',
        Cell: row => {
            const username = row.value;
            return <p>{username}</p>;
        },
        minWidth:
            40 +
            calculateColumnWidth(
                documentComments,
                'createdBy',
                intl.formatMessage(messages.createdBy),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.emailsToNotify)}</span>
            </div>
        ),
        accessor: 'emailsToNotify',
        Cell: row => {
            const rowValue = row.value?.replaceAll(";", " ");
            return <p>{rowValue}</p>;
        },
        minWidth: calculateColumnWidth(
                documentComments,
                'emailsToNotify',
                intl.formatMessage(messages.emailsToNotify),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.createdAt)}</span>
            </div>
        ),
        accessor: 'createdAt',
        Cell: row => {
            const date = new Date(row.value).toLocaleString(navigator.language);
            return (
                <div className='customer-container'>
                    <div className=''>{date}</div>
                </div>
            );
        },
        minWidth: 
        20 + calculateColumnWidth(
            documentComments,
            'createdAt',
            intl.formatMessage(messages.createdAt),
        ),
    }
];
