import { defineMessages } from "react-intl";
import globalMessages from "../../../../utils/globalMessages";

export default defineMessages({
  ...globalMessages,

  pendingInvoicesCounter: {
    id: "dashboard.pendingInvoicesCounter",
    defaultMessage: "nota(s) pendente(s)"
  }
});
