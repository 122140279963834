import { defineMessages } from 'react-intl';

const globalMessages = defineMessages({
    emissionDate: {
        id: 'global.emissionDate',
        defaultMessage: 'Data de Emissão',
    },
    previousButton: {
        id: 'global.previousButton',
        defaultMessage: '<',
    },
    nextButtonText: {
        id: 'global.nextButton',
        defaultMessage: '>',
    },
    searchButton: {
        id: 'global.searchButton',
        defaultMessage: 'Buscar {entity}',
    },
    noDataText: {
        id: 'global.noDataText',
        defaultMessage: 'Sem dados',
    },
    loadingText: {
        id: 'global.loadingText',
        defaultMessage: 'Carregando...',
    },
    pageText: {
        id: 'global.pageText',
        defaultMessage: 'Página',
    },
    ofText: {
        id: 'global.ofText',
        defaultMessage: 'de',
    },
    rowsText: {
        id: 'global.rowsText',
        defaultMessage: 'linhas',
    },
    pageJumpText: {
        id: 'global.pageJumpText',
        defaultMessage: 'pular para página',
    },
    rowsSelectorText: {
        id: 'global.rowsSelectorText',
        defaultMessage: 'linhas por página',
    },
    newButtonText: {
        id: 'global.newButtonText',
        defaultMessage: 'Novo {entity}',
    },
    sendButtonText: {
        id: 'global.sendButtonText',
        defaultMessage: 'Enviar',
    },
    deleteButton: {
        id: 'global.deleteButton',
        defaultMessage: 'Excluir',
    },
    editButton: {
        id: 'global.editButton',
        defaultMessage: 'Editar',
    },
    primaryColorText: {
        id: 'global.primaryColorText',
        defaultMessage: 'Cor Para Customização',
    },
    logoNormalText: {
        id: 'global.logoNormalText',
        defaultMessage: 'Logomarca Tamanho Normal',
    },
    logoMiniText: {
        id: 'global.logoMiniText',
        defaultMessage: 'Logomarca Tamanho Pequeno',
    },
    yesText: {
        id: 'global.yesText',
        defaultMessage: 'Sim',
    },
    noText: {
        id: 'global.noText',
        defaultMessage: 'Não',
    },
    buttonChangeText: {
        id: 'global.buttonChangeText',
        defaultMessage: 'Trocar',
    },
    minLengthPassword: {
        id: 'global.minLengthPassword',
        defaultMessage: 'Sua senha deve ter no mínimo 8 caracteres',
    },
    confirmDeleteText: {
        id: 'global.confirmDeleteText',
        defaultMessage: 'Tem certeza que deseja excluir?',
    },
    confirmCancelText: {
        id: 'global.confirmCancelText',
        defaultMessage: 'Tem certeza que deseja cancelar?',
    },
    confirmReturnToReviewTitle: {
        id: 'global.confirmReturnToReviewTitle',
        defaultMessage: 'Confirmar retorno',
    },
    confirmReturnToReviewText: {
        id: 'global.confirmReturnToReviewText',
        defaultMessage: 'Tem certeza que deseja retornar o documento para revisão?',
    },
    confirmReportCancelText: {
        id: 'global.confirmReportCancelText',
        defaultMessage:
            'Ao informar o cancelamento da nota fiscal, primeiro garanta que a nota fiscal já está cancelada na prefeitura onde foi emitida. Esta ação se torna irreversível.',
    },
    confirmDeactiveText: {
        id: 'global.confirmDeactiveText',
        defaultMessage: 'Tem certeza que deseja desativar?',
    },
    confirmDeactiveCropText: {
        id: 'global.confirmDeactiveCropText',
        defaultMessage: 'Tem certeza que deseja inativar o crop?',
    },
    confirmActiveCropText: {
        id: 'global.confirmActiveCropText',
        defaultMessage: 'Tem certeza que deseja ativar o crop?',
    },
    confirmButton: {
        id: 'global.confirmButton',
        defaultMessage: 'Confirmar',
    },
    cancelButton: {
        id: 'global.cancelButton',
        defaultMessage: 'Cancelar',
    },
    rejectButton: {
        id: 'global.rejectButton',
        defaultMessage: 'Rejeitar',
    },
    fileText: {
        id: 'global.fileText',
        defaultMessage: 'Arquivo',
    },
    submitButton: {
        id: 'global.submitButton',
        defaultMessage: 'Enviar',
    },
    attachmentButton: {
        id: 'global.attachmentButton',
        defaultMessage: 'Anexos',
    },
    closeButton: {
        id: 'global.closeButton',
        defaultMessage: 'Fechar',
    },
    commentsButton: {
        id: 'global.commentsButton',
        defaultMessage: 'Comentários',
    },
    newAttachmentButton: {
        id: 'global.newAttachmentButton',
        defaultMessage: '+ Anexo',
    },
    updateButton: {
        id: 'global.updateButton',
        defaultMessage: 'Atualizar',
    },
    available: {
        id: 'global.available',
        defaultMessage: 'Disponíveis',
    },
    active: {
        id: 'global.active',
        defaultMessage: 'Ativas',
    },
    order: {
        id: 'global.order',
        defaultMessage: 'Ordem',
    },
    type: {
        id: 'global.type',
        defaultMessage: 'Tipo',
    },
    parameters: {
        id: 'global.parameters',
        defaultMessage: 'Parâmetros',
    },
    saveButton: {
        id: 'global.saveButton',
        defaultMessage: 'Salvar',
    },
    addButton: {
        id: 'global.addButton',
        defaultMessage: 'Novo',
    },
    text: {
        id: 'global.text',
        defaultMessage: 'Texto',
    },
    from: {
        id: 'global.from',
        defaultMessage: 'A partir de',
    },
    to: {
        id: 'global.to',
        defaultMessage: 'até',
    },

    characters: {
        id: 'global.characters',
        defaultMessage: 'Caracteres',
    },
    size: {
        id: 'global.size',
        defaultMessage: 'Tamanho',
    },
    newText: {
        id: 'global.newText',
        defaultMessage: 'Texto novo',
    },
    oldText: {
        id: 'global.oldText',
        defaultMessage: 'Texto antigo',
    },
    actions: {
        id: 'global.actions',
        defaultMessage: 'Ações {entity}',
    },
    errorText: {
        id: 'global.errorText',
        defaultMessage:
            'Erro {entity}: Por favor, entre em contato com o suporte',
    },
    createdAt: {
        id: 'global.createdAt',
        defaultMessage: 'Criado em',
    },
    integratedAt: {
        id: 'global.integratedAt',
        defaultMessage: 'Integrado em',
    },
    createdBy: {
        id: 'global.createdBy',
        defaultMessage: 'Criado por',
    },
    helloText: {
        id: 'global.helloText',
        defaultMessage: 'Olá, {entity}',
    },
    january: {
        id: 'global.months.january',
        defaultMessage: 'Janeiro',
    },
    february: {
        id: 'global.months.february',
        defaultMessage: 'Fevereiro',
    },
    march: {
        id: 'global.months.march',
        defaultMessage: 'Março',
    },
    april: {
        id: 'global.months.april',
        defaultMessage: 'Abril',
    },
    may: {
        id: 'global.months.may',
        defaultMessage: 'Maio',
    },
    june: {
        id: 'global.months.june',
        defaultMessage: 'Junho',
    },
    july: {
        id: 'global.months.july',
        defaultMessage: 'Julho',
    },
    august: {
        id: 'global.months.august',
        defaultMessage: 'Agosto',
    },
    september: {
        id: 'global.months.september',
        defaultMessage: 'Setembro',
    },
    october: {
        id: 'global.months.october',
        defaultMessage: 'Outubro',
    },
    november: {
        id: 'global.months.november',
        defaultMessage: 'Novembro',
    },
    december: {
        id: 'global.months.december',
        defaultMessage: 'Dezembro',
    },
    create: {
        id: 'global.create',
        defaultMessage: 'Criar',
    },
    welcome: {
        id: 'global.welcome',
        defaultMessage: 'Bem-vindo, ',
    },
    defaultError: {
        id: 'global.defaultError',
        defaultMessage: 'Oh, não! Alguma coisa deu errado...',
    },
    date: {
        id: 'global.date',
        defaultMessage: 'Data',
    },
    developedBy: {
        id: 'global.developedBy',
        defaultMessage: 'Desenvolvido por',
    },
    cities: {
        id: 'global.cities',
        defaultMessage: 'Cidades',
    },
    states: {
        id: 'global.states',
        defaultMessage: 'Estados',
    },
    complete: {
        id: 'global.complete',
        defaultMessage: 'Completo',
    },
    search: {
        id: 'global.search',
        defaultMessage: 'Pesquisar',
    },
    copy: {
        id: 'global.copy',
        defaultMessage: 'Copiar',
    },
    export: {
        id: 'global.export',
        defaultMessage: 'Exportar',
    },
    import: {
        id: 'global.import',
        defaultMessage: 'Importar',
    },
    anticipate: {
        id: 'global.anticipate',
        defaultMessage: 'Adiantar',
    },
    accept: {
        id: 'global.accept',
        defaultMessage: 'Aceitar',
    },
    warning: {
        id: 'global.warning',
        defaultMessage: 'Atenção',
    },
    confirmAnticipate: {
        id: 'global.confirmAnticipate',
        defaultMessage: 'Tem certeza que deseja adiantar?',
    },
    fields: {
        id: 'global.fields',
        defaultMessage: 'Campos',
    },
    description: {
        id: 'global.description',
        defaultMessage: 'Descrição',
    },
    required: {
        id: 'global.required',
        defaultMessage: 'Obrigatório',
    },
    amount: {
        id: 'global.amount',
        defaultMessage: 'Quantidade',
    },
    value: {
        id: 'global.value',
        defaultMessage: 'Valor',
    },
    total: {
        id: 'global.total',
        defaultMessage: 'Total',
    },

    registrationInfo: {
        id: 'global.registrationInfo',
        defaultMessage: 'Dados Cadastrais',
    },
    supplierName: {
        id: 'global.supplierName',
        defaultMessage: 'Nome do fornecedor',
    },
    globalSupplier: {
        id: 'global.globalSupplier',
        defaultMessage: 'Fornecedor',
    },
    status: {
        id: 'global.status',
        defaultMessage: 'Status',
    },
    selectPlaceholder: {
        id: 'global.selectPlaceholder',
        defaultMessage: 'Digite para pesquisa...',
    },
    selectAll: {
        id: 'global.selectAll',
        defaultMessage: 'Selecionar todos',
    },
    select: {
        id: 'global.select',
        defaultMessage: 'Selecionar',
    },
    home: {
        id: 'global.home',
        defaultMessage: 'Início',
    },
    orders: {
        id: 'global.orders',
        defaultMessage: 'Pedidos',
    },
    items: {
        id: 'global.Items',
        defaultMessage: 'Itens {entity}',
    },
    transactions: {
        id: 'global.transactions',
        defaultMessage: 'Pagamentos',
    },
    city: {
        id: 'global.city',
        defaultMessage: 'Cidade',
    },
    returnToReviewStep: {
        id: 'global.returnToReviewStep',
        defaultMessage: 'Retornar documento para revisão',
    },
    selectAllCities: {
        id: 'global.selectAllCities',
        defaultMessage: 'Desmarcar todas as prefeituras',
    },
    diselectAllCities: {
        id: 'global.diselectAllCities',
        defaultMessage: 'Marcar todas as prefeituras',
    },
    dateAndTime: {
        id: 'global.dateAndTime',
        defaultMessage: 'Data/Hora'
    },
    downloadLink: {
        id: 'global.download',
        defaultMessage: 'Download'
    },
    globalMap: {
        id: 'global.globalMap',
        defaultMessage: 'Mapeamento'
    },
    pendingFiles: {
        id: 'global.pendingFiles',
        defaultMessage: 'Arquivos Pendentes'
    },
    title: {
        id: 'invoices.title',
        defaultMessage: 'Documentos',
    },
    titleInvoice: {
        id: 'invoices.titleInvoice',
        defaultMessage: 'Nota Fiscal',
    },
    sender: {
        id: 'invoices.sender',
        defaultMessage: 'Remetente',
    },
    issuer: {
        id: 'invoices.issuer',
        defaultMessage: 'Emitente',
    },
    recipient: {
        id: 'invoices.recipient',
        defaultMessage: 'Destinatário',
    },
    accessKey: {
        id: 'invoices.accessKey',
        defaultMessage: 'Chave de acesso',
    },
    invoiceNumber: {
        id: 'invoices.invoiceNumber',
        defaultMessage: 'Número',
    },
    invoiceNumberFilter: {
        id: 'invoices.invoiceNumberFilter',
        defaultMessage: 'Número do Documento',
    },
    cteNumber: {
        id: 'invoices.cteNumber',
        defaultMessage: 'Número do CTE',
    },
    subtitle: {
        id: 'invoices.subtitle',
        defaultMessage: ' {length} documentos encontrados',
    },
    statusWaiting: {
        id: 'invoices.statusWaiting',
        defaultMessage: 'Esperando resultado',
    },
    statusInProgress: {
        id: 'invoices.statusInProgress',
        defaultMessage: 'Em processamento',
    },
    statusCompleted: {
        id: 'invoices.statusCompleted',
        defaultMessage: 'Completo',
    },
    statusFailed: {
        id: 'invoices.statusFailed',
        defaultMessage: 'Falhou',
    },
    statusReview: {
        id: 'invoices.statusReview',
        defaultMessage: 'Em revisão',
    },
    statusText: {
        id: 'invoices.statusText',
        defaultMessage: 'Descrição do status',
    },
    tabNfse: {
        id: 'invoices.tabNfse',
        defaultMessage: 'NFS-e',
    },
    tabNfe: {
        id: 'invoices.tabNfe',
        defaultMessage: 'NF-e',
    },
    tabCte: {
        id: 'invoices.tabCte',
        defaultMessage: 'CT-e',
    },
    tabOther: {
        id: 'invoices.tabOther',
        defaultMessage: 'Outros Pagamentos',
    },
    newButtonText: {
        id: 'global.newButtonText',
        defaultMessage: 'Nova {entity}',
    },
    selectSupplier: {
        id: 'suppliers.selectSupplier',
        defaultMessage: 'Selecione o fornecedor',
    },
    selectDate: {
        id: 'documents.selectDate',
        defaultMessage: 'Selecione um range de datas',
    },
    labelMaps: {
        id: 'invoices.labelMaps',
        defaultMessage: 'Tipos',
    },
    titleContractor: {
        id: 'contractors.titleContractor',
        defaultMessage: 'Contratante {entity}',
    },
    titleOnlyContractor: {
        id: 'contractors.titleOnlyContractor',
        defaultMessage: 'Contratante',
    },
    titleSupplier: {
        id: 'suppliers.titleSupplier',
        defaultMessage: 'Fornecedor {entity}',
    },
    titleSource: {
        id: 'documents.titleSource',
        defaultMessage: 'Origem',
    },
    titleOnlySupplier: {
        id: 'suppliers.titleOnlySupplier',
        defaultMessage: 'Fornecedor',
    },
    integratedAtDocument: {
        id: 'documentos.integratedAtDocument',
        defaultMessage: 'Integrado Em',
    },
    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },
    canUploadError: {
        id: 'invoices.canUploadError',
        defaultMessage:
            'Documento não vinculado ao fornecedor, entre em contato com o suporte.',
    },
    canJustificationError: {
        id: 'documents.canJustificationError',
        defaultMessage: 'Excedido o limite máximo de 600 caracteres.',
    },
    uploadWOOrderNotAllowed: {
        id: 'invoices.uploadWOOrderNotAllowed',
        defaultMessage:
            'Não é permitido enviar este tipo de documento sem pedido. Por favor faça o envio através da aba "Pedidos".',
    },
    selectType: {
        id: 'invoices.selectType',
        defaultMessage: 'Selecione o tipo',
    },
    success: {
        id: 'documents.success',
        defaultMessage: 'Sucesso',
    },
    error: {
        id: 'documents.error',
        defaultMessage: 'Erro',
    },
    pending: {
        id: 'documents.pending',
        defaultMessage: 'Pendente',
    },
    invalidinterval: {
        id: 'document.invalidinterval',
        defaultMessage: 'Intervalo de datas deve ser inferior a 31 dias',
    },
    categoryType: {
        id: 'maps.categoryType',
        defaultMessage: 'Categoria',
    },
    subCategory: {
        id: 'maps.subCategory',
        defaultMessage: 'SubCategoria',
    },
    city: {
        id: 'invoices.city',
        defaultMessage: 'Cidade',
    },
    titleTaker: {
        id: 'contractors.titleTaker',
        defaultMessage: 'Tomador {entity}',
    },
    titleProvider: {
        id: 'suppliers.titleProvider',
        defaultMessage: 'Prestador {entity}',
    },
    comment: {
        id: 'documentComments.comment',
        defaultMessage: 'Comentário',
    },
    createdBy: {
        id: 'documentComments.createdBy',
        defaultMessage: 'Usuário',
    },
    createdAt: {
        id: 'documentComments.createdAt',
        defaultMessage: 'Criado Em',
    },
    emailsToNotify: {
        id: 'documentComments.emailsToNotify',
        defaultMessage: 'Emails para notificação',
    }
});

export default globalMessages;
