import React from 'react';

const Export = ({
    width = '15',
    height = '18',
    viewBox = '0 0 15 18',
    fill = 'none',
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox={viewBox}
            fill={fill}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M14.375 8.37525V15.8752C14.375 16.2068 14.2433 16.5247 14.0089 16.7591C13.7745 16.9936 13.4565 17.1252 13.125 17.1252H1.875C1.54348 17.1252 1.22554 16.9936 0.991116 16.7591C0.756696 16.5247 0.625 16.2068 0.625 15.8752V8.37525C0.625 8.04373 0.756696 7.72579 0.991116 7.49136C1.22554 7.25694 1.54348 7.12525 1.875 7.12525H3.75C3.91576 7.12525 4.07473 7.1911 4.19194 7.30831C4.30915 7.42552 4.375 7.58449 4.375 7.75025C4.375 7.91601 4.30915 8.07498 4.19194 8.19219C4.07473 8.3094 3.91576 8.37525 3.75 8.37525H1.875V15.8752H13.125V8.37525H11.25C11.0842 8.37525 10.9253 8.3094 10.8081 8.19219C10.6908 8.07498 10.625 7.91601 10.625 7.75025C10.625 7.58449 10.6908 7.42552 10.8081 7.30831C10.9253 7.1911 11.0842 7.12525 11.25 7.12525H13.125C13.4565 7.12525 13.7745 7.25694 14.0089 7.49136C14.2433 7.72579 14.375 8.04373 14.375 8.37525ZM4.81719 5.06744L6.875 3.00884V10.2502C6.875 10.416 6.94085 10.575 7.05806 10.6922C7.17527 10.8094 7.33424 10.8752 7.5 10.8752C7.66576 10.8752 7.82473 10.8094 7.94194 10.6922C8.05915 10.575 8.125 10.416 8.125 10.2502V3.00884L10.1828 5.06744C10.3001 5.18471 10.4591 5.2506 10.625 5.2506C10.7909 5.2506 10.9499 5.18471 11.0672 5.06744C11.1845 4.95016 11.2503 4.7911 11.2503 4.62525C11.2503 4.4594 11.1845 4.30034 11.0672 4.18306L7.94219 1.05806C7.88414 0.99995 7.81521 0.95385 7.73934 0.922398C7.66346 0.890945 7.58213 0.874756 7.5 0.874756C7.41787 0.874756 7.33654 0.890945 7.26066 0.922398C7.18479 0.95385 7.11586 0.99995 7.05781 1.05806L3.93281 4.18306C3.81554 4.30034 3.74965 4.4594 3.74965 4.62525C3.74965 4.7911 3.81554 4.95016 3.93281 5.06744C4.05009 5.18471 4.20915 5.2506 4.375 5.2506C4.54085 5.2506 4.69991 5.18471 4.81719 5.06744Z'
                fill='black'
            />
        </svg>
    );
};

export default Export;
