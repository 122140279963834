import React from 'react';

function DownArrow({
    width = '10',
    height = '7',
    viewBox = '0 0 10 7',
    fill = 'none',
}) {
    return (
        <svg
            width={width}
            height={height}
            viewBox={viewBox}
            fill={fill}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M1 1.5L5 5.5L9 1.5'
                stroke='black'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}

export default DownArrow;
