import React, { useContext, useEffect, useState } from 'react';
import { TableContext } from '../';
import TComponents from '.';
import { sortedDataById } from '../../../../utils/arrayUtils';

function Body(props) {
    const { onRowClick } = props;

    const {
        variant,
        data,
        sorting,
        pagination,
        tableStyles,
        additionalRows,
        filters,
        minTableSize,
    } = useContext(TableContext);

    const [filteredData, setFilteredData] = useState(null);

    const bodyStyles = tableStyles?.children.body;

    const { pageIndex, pageSize } = pagination;

    useEffect(() => {
        const sortedData = sortedDataById(data, sorting?.id, sorting?.desc);

        const newData = sortedData.filter(item =>
            Object.values(filters).every(filter =>
                filter.selectedOptions?.some(
                    option =>
                        option.toLowerCase() === item[filter.key].toLowerCase(),
                ),
            ),
        );

        const sliceData = newData.slice(
            pageIndex * pageSize,
            (pageIndex + 1) * pageSize,
        );

        setFilteredData(sliceData);
    }, [filters, pageIndex, pageSize, sorting]);

    return (
        <tbody
            className={`${variant}-body ${bodyStyles.className}`}
            style={Object.assign(
                {
                    minWidth: minTableSize,
                },
                bodyStyles.style,
            )}
        >
            {filteredData?.map((row, index) => (
                <TComponents.Row
                    key={index}
                    row={row}
                    onRowClick={onRowClick}
                />
            ))}
            {additionalRows?.map((row, index) => (
                <TComponents.Row
                    key={index}
                    row={row}
                    onRowClick={onRowClick}
                    allFields={row?.['allFields']}
                    isAdditional
                />
            ))}
        </tbody>
    );
}

export default Body;
