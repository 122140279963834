import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import messages from './DocumentDetailsForm.intl';

import { Row, Col } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';

import Breadcrumb from '../Breadcrumb/Breadcrumb';
import Modal from '../Modal/Modal';
import Header from '../Header/Header';
import Loading from '../Loading/Loading';
import Viewer from '../Viewer/Viewer';
import DynamicForm from '../DynamicForm/DynamicForm';
import { AuthContext } from '../../context/AuthContext';
import ButtonBadge from '../ButtonBadge/ButtonBadge';
import DropdownListButton from '../DropdownListButton/DropdownListButton';
import ModalConfirmReturnToReview from '../ModalConfirmReturnToReview/ModalConfirmReturnToReview';
import DynamicPanel from '../DynamicPanel/DynamicPanel';
import EvtSelect from '../EvtSelect/EvtSelect';

const categoryOptions = [
    {
        label: 'Padrão',
        value: 999999,
    }
]

const mixedCategoryValue = 999998;

const DocumentDetailsForm = props => {
    const authContext = useContext(AuthContext);
    const [boxes, setBoxes] = useState([]);
    const [collapse, setCollapse] = useState([1, 2, 3, 4, 5, 6]);
    const [fieldsWithError, setFieldsWithError] = useState([]);
    const [showModalSendDocument, setShowModalSendDocument] = useState(false);
    const [
        showModalDocumentDefaultMap,
        setShowModalDocumentDefaultMap,
    ] = useState(false);
    const [submitValues, setSubmitValues] = useState({});
    const [showReturnToReviewModal, setShowReturnToReviewModal] = useState(false);
    const [selectedFormField, setSelectedFormField] = useState(null);
    const [fields, setFields] = useState([]);
    const [selectedCategoryMap, setCategoryMap] = useState(null);
    const { intl, loadingDetails } = props;

    const selectedDocument = props.selectedDocument || {};
    const ocrOperationResult = props.ocrOperationResult || [];
    const currentStep = selectedDocument.currentStep || {};
    const form = selectedDocument.form || {};
    const IAMapping = selectedDocument?.hasIaMapping || false;
    const validationErrors = props.validationErrors || [];
    const map = selectedDocument?.map;
    const isAttachment = props.selectedDocument?.isAttachment || false;

    const categories = categoryOptions.concat(props.categories?.filter(item => item.code !== mixedCategoryValue.toString()).map((item, _) => {
                    return {
                        label: item.name,
            value: parseInt(item.code)
        }
    })).sort((item, next) => -(item.value - next.value));

    useEffect(() => {
        if (form?.fields?.length > 0) {
            const IdField = {
                fieldId: '000000000000000000000000',
                name: 'ID do documento',
                key: 'viewDocumentId',
                constraints: { required: false },
                formGroup: 1,
                layout: { xs: 6, lg: 6, md: 6, sm: 6, xl: 6 },
                type: 'Text',
                value: selectedDocument.documentId,
                boundingBox: { x: 0, y: 0, height: 0, width: 0, page: 1 },
                lines: {},
                hideMapInDocument: false,
                isOCRSuccess: false,
                isDocumentField: true,
                edited: false,
                documentFieldsListId: null,
            };
           const arrayFields = form.fields
           arrayFields.unshift(IdField)
           setFields(arrayFields)
        }
    }, [form.fields]);

    const url =
        map?.mapVersion === 2
            ? `${window.API_URI}/Documents/${selectedDocument.documentId}/File`
            : `${window.API_URI}/Documents/${
                  selectedDocument.documentId
              }/File/Preview`;

    const hasDocumentUpdateClaim = authContext.hasClaim('document-update');

    const isRevisionStep =
        currentStep.order === 3 && currentStep.status === 'pending';

    const dynamicFormEnabled = hasDocumentUpdateClaim && isRevisionStep;

    const shouldSelectCategoryType = checkIfCategoryIsMixed();

    const IsXmlFields =
        selectedDocument?.map?.fileType?.toLowerCase() === 'xml' &&
        currentStep.order === 3 &&
        currentStep.status === 'pending';

    const transactionButtonEnabled =
        authContext.hasClaim('transaction-view') &&
        currentStep.status !== 'error' &&
        currentStep.order > 3;

    const hasRejectJustification = authContext.hasClaim(
        'document-reject-justification',
    );
    const hideSubmittButton = authContext.hasClaim(
        'document-hide-submit-button',
    );

    const canViewCommentsButtom = authContext.hasClaim(
        'document-comment-view',
    );

    const isSupplier = authContext.hasClaim('role-level-supplier');

    const shouldShowCancelButton =
        currentStep.order === 3 && currentStep.status === 'pending';

    const shouldShowRejectButton =
        hasRejectJustification &&
        currentStep.order === 3 &&
        currentStep.status === 'pending';

    const showInformCancelButton =
        !authContext.hasClaim('document-hide-info-emitter-rejection') &&
        currentStep.status !== 'error' &&
        compareDate(selectedDocument.createdAt, 6);

    const shouldShowReturnToReviewStepButton =
        authContext.hasClaim('document-update-status') &&
        currentStep?.order === 4 && currentStep?.status !== 'success';

    const routes = [
        {
            path: '/home',
            name: intl.formatMessage(messages.home),
            active: false,
        },
        {
            path: `/${props.documentPath}`,
            name: intl.formatMessage(messages.title),
            active: false,
        },
        {
            path: `/${props.documentPath}/:id`,
            name: intl.formatMessage(messages.titleDocument, {
                entity: '',
            }),
            active: true,
        },
    ];

    const [isDraf, setIsDraf] = useState(false);

    useEffect(() => {
        if (
            selectedDocument?.documentType === 'NFS' &&
            !selectedDocument?.hasIaMapping &&
            selectedDocument?.map?.name === 'NFSEPADRAO' &&
            currentStep.order === 3 &&
            currentStep.status === 'pending'
        ) {
            handleToggleModalSendModalDocumentDefaultMap();
        }
    }, [selectedDocument?.map]);

    useEffect(() => {
        if (fields && fields.length !== 0) {
            setBoxes(getBoxes(fields));
        }
    }, [fields]);

    useEffect(() => {
        const errors = Object.keys(validationErrors);
        setFieldsWithError(errors);
    }, [validationErrors]);

    function checkIfCategoryIsMixed() {
        return selectedDocument?.supplier?.categoryMap === mixedCategoryValue && dynamicFormEnabled;
    }

    function compareDate(dateToCompare, addMonths) {
        const dateNow = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate(),
            0,
            0,
            0,
        );
        const comparisonDate = new Date(
            dateNow.setMonth(dateNow.getMonth() + Number(-addMonths)),
        );

        return new Date(dateToCompare) > comparisonDate;
    }

    function handlePaymentsButtonClick(e) {
        props.history.push({
            pathname: `/transactions`,
            state: { documentId: selectedDocument.documentId },
        });
    }

    function handleFormGroupToogle(e, index) {
        const found = collapse.find(col => col === Number(index));

        if (found) {
            const filtered = collapse.filter(col => col !== Number(index));
            setCollapse(filtered);
        } else {
            setCollapse([...collapse, Number(index)]);
        }
    }

    function handleValidFormSubmit(e, values) {
        if (!isDraf) {
            setSubmitValues({ e, values, isDraf, selectedCategoryMap });
            handleToggleModalSendDocument();
        } else {
            props.handleValidFormSubmit(e, values, isDraf,selectedCategoryMap);
        }
    }

    function handleIsDraftButtonClick() {
        setIsDraf(true);
    }

    function handleCancelButtonModal(e) {
        props.handleCancelButtonModal();
    }

    function handleInformCancelButtonClick() {
        props.handleInformCancelButtonClick();
    }

    function handleToggleReturnToReviewModal() {
        setShowReturnToReviewModal(!showReturnToReviewModal);
    }

    function handleReturnToReviewStepClick() {
        setShowReturnToReviewModal(!showReturnToReviewModal)
        props.handleReturnToReviewStepClick();
    }

    function handleRejectButtonModal(e) {
        props.handleRejectButtonModal();
    }
    /* 
    function handleReportCancelButtonModal(e) {
        e.preventDefault();
        props.handleReportCancelButtonModal();
    } */

    function handleDownloadFile() {
        const downloadUrl = `${window.API_URI}/Documents/${
            selectedDocument.documentId
        }/File`;
        window.open(downloadUrl, 'Download');
    }

    function handleDynamicFieldFocus(e, key) {
        const field = fields.find(ff => ff.key === key);

        const bb = field.boundingBox
            ? {
                  ...field.boundingBox,
                  key,
              }
            : {
                  key,
                  x: 0,
                  y: 0,
                  width: 0,
                  height: 0,
                  page: 1,
              };

        setBoxes([bb]);
        setSelectedFormField(field);
    }

    function handleDynamicFieldChange(e, key) {
        if (!key) return;
        setFieldsWithError(fieldsWithError.filter(fkey => fkey !== key));
    }

    function handleDynamicFieldBlur(e, index) {
        const boxes = getBoxes(fields);
        setBoxes(boxes);
    }

    function getBoxes(fields) {
        return fields.map((field, index) => {
            if (!field.boundingBox) return null;

            if (field.hideMapInDocument) return null;

            return field
                ? {
                      key: `box${index}`,
                      x: field.boundingBox.x,
                      y: field.boundingBox.y,
                      width: field.boundingBox.width,
                      height: field.boundingBox.height,
                      page: field.boundingBox?.page || 1,
                  }
                : {
                      key: `box${index}`,
                      x: 0,
                      y: 0,
                      width: 0,
                      height: 0,
                      page: 1,
                  };
        });
    }

    function getSteps() {
        let step = {};
        var steps = [];

        const selectedDocumentSteps = selectedDocument.steps || [];

        selectedDocumentSteps.forEach(item => {
            if (Object.keys(step).length !== 0 && step.order !== item.order) {
                steps.push(step);
            }

            step = item;
        });

        Object.keys(currentStep).length > 0 && steps.push(currentStep);
        return steps;
    }

    const steps = getSteps();
    const words =
        ocrOperationResult.lines &&
        ocrOperationResult.lines.flatMap(l => l.words);
    let verboseBoxes =
        words &&
        words.map((word, index) => {
            return {
                ...word.boundingBox,
                key: `bb${index}`,
            };
        });

    function handleToggleModalSendDocument() {
        setShowModalSendDocument(!showModalSendDocument);
    }

    function handleToggleModalSendModalDocumentDefaultMap() {
        setShowModalDocumentDefaultMap(!showModalDocumentDefaultMap);
    }

    function handleToggleSendDocumentCancel() {
        setSubmitValues({});
        handleToggleModalSendDocument();
    }

    function handleToggleModalSendModalDocumentDefaultMapCancel() {
        setSubmitValues({});
        handleToggleModalSendModalDocumentDefaultMap();
    }

    function handleSubmitForm() {
        const { e, values, isDraf, selectedCategoryMap } = submitValues;
        props.handleValidFormSubmit(e, values, isDraf, selectedCategoryMap);
        setSubmitValues({});
        handleToggleModalSendDocument();
    }

    const category = categories.find(item => item.value == selectedCategoryMap);

    const handleSupplierChange = (value) => {
        setCategoryMap(value.value);
    };

    return (
        <>
            <Breadcrumb routes={routes} />
            <header className='row'>
                <section className='title'>
                    <Header
                        title={intl.formatMessage(messages.titleDocument)}
                    />
                </section>
            </header>
            <Loading loading={loadingDetails} />
            <div
                id='invoice-status'
                className={'row invoice-status invoice-status-' + steps.length}
            >
                {steps.map((item, index) => {
                    let ballClass =
                        'invoice-status-step-ball ' + item.status.toLowerCase();

                    const createdAt =
                        new Date(item.createdAt).toLocaleDateString(
                            navigator.language,
                        ) +
                        ' ' +
                        new Date(item.createdAt).toLocaleTimeString(
                            navigator.language,
                        );

                    let itemText = item.text;

                    if (isSupplier && item.altText) {
                        itemText = item.altText;
                    }

                    return (
                        <div
                            key={index}
                            className='element-parent invoice-status-step'
                        >
                            <div className={ballClass} />
                            <span className='invoice-status-step-strike' />
                            <span className='custom-tooltip'>
                                <span>{itemText}</span>
                                <span className='data-tooltip'>
                                    {'\n Atualizado em: \n' + createdAt}
                                </span>
                            </span>
                        </div>
                    );
                })}
            </div>

            <section className='map-nota row'>
                <section className='col-6'>
                    {selectedDocument?.fileId && (
                        <Viewer
                            readOnly={true}
                            url={url}
                            urlDownload={`${window.API_URI}/Documents/${
                                selectedDocument.documentId
                            }/File`}
                            urlXml={
                                selectedDocument.map?.fileType === 'Xml'
                                    ? `${window.API_URI}/Documents/${
                                          selectedDocument.documentId
                                      }/Xml`
                                    : ''
                            }
                            boxes={boxes}
                            verboseBoxes={verboseBoxes}
                            handleShowVerboseBoxesChecked={
                                props.handleShowVerboseBoxesChecked
                            }
                            hasEvents={
                                selectedDocument.documentType === 'CTE' ||
                                selectedDocument.documentType === 'NFS'
                                    ? true
                                    : false
                            }
                            handleEventsClick={props.handleShowEvents}
                            handleHistoryClick={props.handleShowHistory}
                            selectedItem={selectedFormField}
                            mapInfo={selectedDocument.map}
                        />
                    )}
                </section>
                <section className='fields-maps col-6'>
                    <AvForm onValidSubmit={handleValidFormSubmit}>
                        <Row
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div style={{ display: 'flex' }}>
                                <Col xs={{ size: 2.4 }}>
                                    {!isAttachment && (
                                        <ButtonBadge
                                            count={String(props.documentsCount)}
                                        />
                                    )}
                                    <button
                                        type='button'
                                        disabled={
                                            loadingDetails || isAttachment
                                        }
                                        className={`btn-info ${isAttachment &&
                                            'btn-disable'}`}
                                        onClick={props.handleShowChildDocuments}
                                    >
                                        {intl.formatMessage(
                                            messages.attachmentButton,
                                        )}
                                    </button>
                                </Col>
                                <Col xs={{ size: 2.4 }}>
                                    <ButtonBadge
                                        count={String(
                                            props.ordersDocumentCount,
                                        )}
                                    />

                                    <button
                                        type='button'
                                        disabled={loadingDetails}
                                        className={`btn-info`}
                                        onClick={
                                            props.handleShowOrdersDocuments
                                        }
                                    >
                                        {intl.formatMessage(
                                            messages.ordersButton,
                                        )}
                                    </button>
                                </Col>
                                {canViewCommentsButtom && (
                                    <Col xs={{ size: 2.4 }}>
                                            <button
                                                type='button'
                                                disabled={loadingDetails}
                                                className={`btn-info`}
                                                onClick={
                                                    props.handleShowDocumentComments
                                                }
                                            >
                                                {intl.formatMessage(
                                                    messages.commentsButton,
                                                )}
                                            </button>
                                    </Col>
                                )}
                                <Col xs={{ size: 2.4 }}>
                                    {transactionButtonEnabled && (
                                        <button
                                            type='button'
                                            disabled={loadingDetails}
                                            className='btn-info'
                                            onClick={
                                                props.handlePaymentsButtonClick
                                            }
                                        >
                                            {intl.formatMessage(
                                                messages.btnTransactions,
                                            )}
                                        </button>
                                    )}
                                </Col>
                            </div>

                            <Col xs={{ size: 2.4 }} style={{ display: 'flex' }}>
                                {dynamicFormEnabled && !hideSubmittButton && (
                                    <button
                                        type='submit'
                                        disabled={loadingDetails}
                                        className='btn-submit'
                                    >
                                        {intl.formatMessage(
                                            messages.submitButton,
                                        )}
                                    </button>
                                )}

                                <DropdownListButton
                                    items={[
                                        //Salvar
                                        {
                                            name: intl.formatMessage(
                                                messages.btnIsDraft,
                                            ),
                                            onClick: () =>
                                                handleIsDraftButtonClick(),
                                            disabled: !dynamicFormEnabled,
                                            type: 'submit',
                                        },
                                        //Informar Cancelamento (Emissor)
                                        {
                                            name: intl.formatMessage(
                                                messages.btnInformCancel,
                                            ),
                                            onClick: () =>
                                                handleInformCancelButtonClick(),
                                            disabled: !showInformCancelButton,
                                        },
                                        //Cancelar
                                        {
                                            name: intl.formatMessage(
                                                messages.cancelButton,
                                            ),
                                            onClick: () =>
                                                handleCancelButtonModal(),
                                            disabled: !shouldShowCancelButton,
                                        },
                                        //Rejeitar
                                        {
                                            name: intl.formatMessage(
                                                messages.rejectButton,
                                            ),
                                            onClick: () =>
                                                handleRejectButtonModal(),
                                            disabled: !shouldShowRejectButton,
                                        },
                                        // Voltar para etapa de revisão
                                        {
                                            name: intl.formatMessage(messages.returnToReviewStep),
                                            onClick: () => handleToggleReturnToReviewModal(),
                                            disabled: !shouldShowReturnToReviewStepButton
                                        }
                                    ]}
                                    className='document-dropdown'
                                />
                            </Col>
                        </Row>
                        <Row>
                            {shouldSelectCategoryType && (
                                <Col className='cards-fields' style={{ height: 'fit-content', overflow: 'visible' }}>
                                    <DynamicPanel
                                        index={3}
                                        headerTitle={intl.formatMessage(messages.shouldSelectCategory)}
                                        hasFields={true}
                                    >
                                        <Row>
                                            <Col
                                                xs={12}
                                                style={{
                                                    paddingBottom: '20px',
                                                }}
                                            >
                                                <EvtSelect
                                                    label={intl.formatMessage(messages.selectCategoryMap)}
                                                    name='categoryMap'
                                                    options={categories}
                                                    key={selectedDocument.categoryType}
                                                    option={
                                                        {
                                                            selectedValueOption: category?.value,
                                                            selectedLabelOption: category?.label,
                                                        }
                                                    }
                                                    required={true}
                                                    handleSelectedOptionChange={
                                                        handleSupplierChange
                                                    }
                                                    disabled={!shouldSelectCategoryType}
                                                />
                                            </Col>
                                        </Row>
                                    </DynamicPanel>
                                </Col>
                            )}
                        </Row>
                        <Row>
                            <Col className='cards-fields' xs={12}>
                                <DynamicForm
                                    fields={fields}
                                    documentType={
                                        selectedDocument.documentType
                                    }
                                    fieldsWithError={fieldsWithError}
                                    collapse={collapse}
                                    toggle={handleFormGroupToogle}
                                    handleDynamicFieldBlur={
                                        handleDynamicFieldBlur
                                    }
                                    handleDynamicFieldFocus={
                                        handleDynamicFieldFocus
                                    }
                                    handleDynamicFieldChange={
                                        handleDynamicFieldChange
                                    }
                                    hasDocumentUpdateClaim={
                                        hasDocumentUpdateClaim
                                    }
                                    isRevisionStep={isRevisionStep}
                                    loading={loadingDetails}
                                    readOnly={
                                        IsXmlFields || !dynamicFormEnabled
                                    }
                                />
                            </Col>
                        </Row>
                    </AvForm>
                </section>
            </section>
            <Modal
                title={intl.formatMessage(messages.confirmSend)}
                isOpen={showModalSendDocument}
                handleToggle={handleToggleSendDocumentCancel}
                hasFooter={true}
                firstButtonClass='delete'
                secondButtonClass='btn-submit'
                firstButtonText={intl.formatMessage(messages.noText)}
                secondButtonText={intl.formatMessage(messages.yesText)}
                toggleFirstButton={handleToggleSendDocumentCancel}
                toggleSecondButton={handleSubmitForm}
            >
                <p>Atenção:</p>
                {intl.formatMessage(messages.sendDocumentConfirmText)}
            </Modal>

            <Modal
                title='Atenção:'
                isOpen={showModalDocumentDefaultMap}
                handleToggle={
                    handleToggleModalSendModalDocumentDefaultMapCancel
                }
                hasFooter={false}
                firstButtonText='OK'
                toggleFirstButton={
                    handleToggleModalSendModalDocumentDefaultMapCancel
                }
            >
                {intl.formatMessage(messages.confirmDefaultMapText)}
            </Modal>
            <ModalConfirmReturnToReview
                handleToggleReturnToReviewModal = {handleToggleReturnToReviewModal}
                showReturnToReviewModal = {showReturnToReviewModal}
                handleReturnToReviewStepClick = {handleReturnToReviewStepClick}
            />
        </>
    );
};

export default injectIntl(withRouter(DocumentDetailsForm));
