import { defineMessages } from "react-intl";
import globalMessages from "../../../../utils/globalMessages";

export default defineMessages({
  ...globalMessages,

  monthlyVolumeByClientChartVolume: {
    id: "dashboard.monthlyVolumeByClientChartVolume",
    defaultMessage: "Volume de notas"
  },
  monthlyVolumeByClientChartInvoices: {
    id: "dashboard.monthlyVolumeByClientChartInvoices",
    defaultMessage: "notas"
  }
});
