import React from 'react';

const Check = ({
    width = '20',
    height = '20',
    viewBox = '0 0 20 20',
    fill = 'none',
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox={viewBox}
            fill={fill}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M7.50001 13.4751L4.02501 10.0001L2.84167 11.1751L7.50001 15.8334L17.5 5.83345L16.325 4.65845L7.50001 13.4751Z'
                fill='black'
            />
        </svg>
    );
};

export default Check;
