import React, { createContext } from 'react';
import Icon from '../../../assets/icons/Index';
import useTable from './view.model';
import TComponents from './components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const TableContext = createContext();

function Table(props) {
    const { exportTableToXLSX, toggleColumn, ...contextValue } = useTable(
        props,
    );
    const {
        filters,
        initialState,
        exportToXLSX,
        variant,
        hasCustomizeColumns,
        data,
        columns,
        tableStyles,
        additionalInfoHeader,
        loading,
        additionalRows,
    } = contextValue;
    const {
        onSortingChange,
        onFiltersChange,
        onPaginationChange,
        onRowClick,
    } = initialState;

    const noRegisterMessage =
        initialState?.noRegisterMessages ||
        `Não foi possível encontrar nenhum registro.`;

    const renderTable =
        !loading &&
        (data?.length > 0 || additionalRows?.length > 0) &&
        !!initialState &&
        !!tableStyles;

    return (
        <TableContext.Provider value={contextValue}>
            <section className={`${variant}-buttons-section`}>
                <div>
                    {filters && (
                        <TComponents.Filter onFiltersChange={onFiltersChange} />
                    )}
                </div>
                <div style={{ display: 'flex', gap: 12 }}>
                    {exportToXLSX && (
                        <button
                            className={`${variant}-btn-secondary`}
                            type='button'
                            onClick={() => exportTableToXLSX(data, columns)}
                        >
                            <Icon name='export' />
                            <span>Exportar para XLSX</span>
                        </button>
                    )}
                    {hasCustomizeColumns && (
                        <TComponents.CustomColumnsButton
                            toggleColumn={toggleColumn}
                        />
                    )}
                </div>
            </section>
            <div className='ui-table'>
                {!!additionalInfoHeader && <div>{additionalInfoHeader}</div>}
                {renderTable ? (
                    <>
                        <div
                            className={`${variant}-component ${
                                tableStyles.className
                            }`}
                            style={Object.assign({}, tableStyles.style)}
                        >
                            <TComponents.Header
                                onSortingChange={onSortingChange}
                            />
                            <TComponents.Body onRowClick={onRowClick} />
                        </div>
                        <TComponents.Pagination
                            onPaginationChange={onPaginationChange}
                        />
                    </>
                ) : (
                    <div className={`${variant}-status-message-loading-error`}>
                        {loading ? (
                            <div className={`${variant}-loading`}>
                                <FontAwesomeIcon
                                    icon='spinner'
                                    size='4x'
                                    pulse
                                />
                                <p>Carregando dados...</p>
                            </div>
                        ) : (
                            <p>{noRegisterMessage}</p>
                        )}
                    </div>
                )}
            </div>
        </TableContext.Provider>
    );
}

export default Table;
