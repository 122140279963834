import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../../../store/actions/index';

import Modal from '../../../../../components/Modal/Modal';
import EvtTable from '../../../../../components/EvtTable/EvtTable';
import PageTemplate from '../../../../../templates/PageTemplate/PageTemplate';
import { columnsDocumentsStatus } from './constants';
import { parseQueryString } from '../../../../../utils/filtersUtils';

const ModalDocumentsStatus = props => {
    const {
        isOpenDocStatus,
        handleToggleDocStatus,
        loadingModal = false,
        cellProperties,
        //mapState
        healthDocuments,
        loadingHealthDocuments,
        onGetHealthDocumentsByKey,
    } = props;

    const healthDocumentsData = healthDocuments?.documents || [];
    const healthDocumentsCount = healthDocuments?.documentsCount || 0;

    const handleEvTableAssociatedStateChange = query => {
        const { skip, take, sort } = parseQueryString(query);
        const { key, ibgeCode, period } = cellProperties;
        onGetHealthDocumentsByKey(period, key, ibgeCode, skip, take, sort);
    };

    const handleGetTrProps = (state, rowInfo, column, instance) => {
        return {
            style: { cursor: 'pointer' },
            onClick: e => {
                if (
                    rowInfo.original.documentType === 'RAW_FILE' ||
                    rowInfo.original.documentType === 9
                )
                    return;
                window.open(`/documents/${rowInfo.original.id}`);
            },
        };
    };

    return (
        <Modal
            isOpen={isOpenDocStatus}
            handleToggle={handleToggleDocStatus}
            size={'xxl'}
            modalBodyClassName={'list-modal'}
        >
            <PageTemplate
                headerTitle={
                    cellProperties.key === 'boleto'
                        ? 'Boletos'
                        : 'Documentos Anexos'
                }
                loading={loadingModal}
            >
                <EvtTable
                    columns={columnsDocumentsStatus(
                        healthDocumentsData,
                        cellProperties.key,
                    )}
                    data={healthDocumentsData}
                    length={healthDocumentsCount}
                    pageSize={5}
                    handleGetTrProps={handleGetTrProps}
                    handleStateChange={handleEvTableAssociatedStateChange}
                    loading={loadingHealthDocuments}
                    defaultSorted={[
                        {
                            id: 'createdAt',
                            desc: true,
                        },
                    ]}
                    countHeaderText={`Documentos encontrados ${healthDocumentsCount}`}
                    fetchPage
                />
            </PageTemplate>
        </Modal>
    );
};

const mapStateToProps = state => {
    return {
        healthDocuments: state.healthReducer.healthDocuments,
        loadingHealthDocuments: state.healthReducer.loadingHealthDocuments,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetHealthDocumentsByKey: (period, key, ibgeCode, skip, take, sort) =>
            dispatch(
                actions.getHealthDocumentsByKey(
                    period,
                    key,
                    ibgeCode,
                    skip,
                    take,
                    sort,
                ),
            ),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ModalDocumentsStatus);
