export const sortedDataById = (data, sortId, sortDesc) =>
    [...data]?.sort((a, b) => {
        if (a[sortId] < b[sortId]) {
            return sortDesc ? -1 : 1;
        }
        if (a[sortId] > b[sortId]) {
            return sortDesc ? 1 : -1;
        }
        return 0;
    });

export const sortAlphabetically = array => {
    return array.sort((a, b) => {
        if (a.toLowerCase() < b.toLowerCase()) return -1;
        if (a.toLowerCase() > b.toLowerCase()) return 1;
        return 0;
    });
};
