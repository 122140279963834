import { defineMessages } from "react-intl";
import globalMessages from "../../../../utils/globalMessages";

export default defineMessages({
  ...globalMessages,

  pendingTransactionsCounter: {
    id: "dashboard.pendingTransactionsCounter",
    defaultMessage: "pagamento(s) em aberto"
  }
});
