import React from 'react';
import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { Container } from 'reactstrap';
import Loading from '../../components/Loading/Loading';

function PageTemplate(props) {
    const {
        children,
        routes = [],
        headerTitle = '',
        headerSubtitle = '',
        headerChildren,
        noBorder,
        loading = false,
    } = props;

    return (
        <Container fluid>
            <Loading loading={loading} />
            <Breadcrumb routes={routes} />
            <header className={`table-screen ${noBorder && 'no-border'}`}>
                <section className='title'>
                    <Header title={headerTitle} subtitle={headerSubtitle} />
                </section>
                {headerChildren}
            </header>
            <section className='content-middle'>{children}</section>
        </Container>
    );
}

export default PageTemplate;
