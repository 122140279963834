import React, { useContext, useEffect, useState } from 'react';
import Icon from '../../../../assets/icons/Index';
import { TableContext } from '../';

function PageSelector() {
    const { data, count, pagination, setPagination, tableStyles } = useContext(
        TableContext,
    );

    const [tableRange, setTableRange] = useState([]);

    const paginationButtonStyles =
        tableStyles?.children.pagination?.children.button;

    const { pageSize } = pagination;

    const calculateRange = (dataCount, rowsToPage) => {
        const range = [];
        const num = Math.ceil(dataCount / rowsToPage);
        for (let i = 1; i <= num; i++) {
            range.push(i);
        }
        return range;
    };

    const arrowClick = direction => {
        if (
            direction === 'right' &&
            pagination.pageIndex + 1 !== tableRange.at(-1)
        ) {
            setPagination(prev => ({ ...prev, pageIndex: prev.pageIndex + 1 }));
        } else if (direction === 'left' && pagination.pageIndex !== 0) {
            setPagination(prev => ({ ...prev, pageIndex: prev.pageIndex - 1 }));
        }
    };

    useEffect(() => {
        const range = calculateRange(count || data?.length, pageSize);
        setTableRange([...range]);
    }, [data, setTableRange, pageSize]);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <span
                className={`pagination-button ${
                    paginationButtonStyles?.className
                }`}
                style={Object.assign({}, paginationButtonStyles?.style)}
                onClick={() => arrowClick('left')}
            >
                <Icon name='leftArrow' />
            </span>
            {tableRange?.map((page, index) => {
                const isSelected = pagination?.pageIndex === page - 1;
                return (
                    <button
                        key={index}
                        className={`pagination-button 
              ${(isSelected && 'selected') || ''} 
              ${paginationButtonStyles?.className}`}
                        style={Object.assign({}, paginationButtonStyles?.style)}
                        onClick={() =>
                            setPagination(prev => ({
                                ...prev,
                                pageIndex: page - 1,
                            }))
                        }
                    >
                        {page}
                    </button>
                );
            })}
            <span
                className={`pagination-button ${
                    paginationButtonStyles?.className
                }`}
                style={Object.assign({}, paginationButtonStyles?.style)}
                onClick={() => arrowClick('right')}
            >
                <Icon name='rightArrow' />
            </span>
        </div>
    );
}

export default PageSelector;
