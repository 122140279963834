import { defineMessages } from "react-intl";
import globalMessages from "../../../../utils/globalMessages";

export default defineMessages({
  ...globalMessages,

  monthlyConsumptionCounter: {
    id: "dashboard.monthlyConsumptionCounter",
    defaultMessage: "das notas foram processadas"
  }
});
