import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from 'reactstrap';

function DropdownListButton(props) {
    const { items, className, handleSetOpen, toggleItem = true } = props;
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        handleSetOpen && handleSetOpen(isOpen);
    }, [isOpen]);

    const handleDropdown = () => setIsOpen(prev => !prev);

    const getDisableOfItems = disableItems => {
        const disableArray = [];
        disableItems.forEach(item => disableArray.push(!!item.disabled));

        if (disableArray.filter(x => x === false).length >= 1) {
            return false;
        } else {
            return true;
        }
    };

    const disableDropdown = props.disable || getDisableOfItems(items);
    return (
        <Dropdown
            isOpen={isOpen}
            toggle={e => {
                handleDropdown();
                e.stopPropagation();
            }}
            direction={'down'}
            className={`${'custom-dropdown' +
                (disableDropdown ? ' disable' : '')} ${className || ''}`}
            disabled={disableDropdown}
            style={{ display: !disableDropdown ? 'block' : 'none' }}
        >
            <DropdownToggle
                style={{
                    width: 30,
                    backgroundColor: '#ffffff',
                    boxShadow: 'none',
                }}
            >
                {props.children ? (
                    <>{props.children}</>
                ) : (
                    <span style={{ color: '#3a6bfc' }}>
                        <FontAwesomeIcon icon='ellipsis-v' />
                    </span>
                )}
            </DropdownToggle>
            <DropdownMenu container='body'>
                {items.map(item => {
                    if (!!item.disabled) {
                        return;
                    }

                    const itemComponent = !!item.component && item.component();

                    if (item.type === 'submit') {
                        return (
                            <button
                                onClick={item.onClick}
                                style={{
                                    borderRadius: 0,
                                    boxShadow: 'none',
                                }}
                                tabIndex={0}
                                role={'menuitem'}
                                type='submit'
                                className='dropdown-item'
                            >
                                <span style={{ marginLeft: 5 }}>
                                    {item.name}
                                </span>
                            </button>
                        );
                    } else {
                        return (
                            <DropdownItem
                                style={{
                                    borderRadius: 0,
                                    boxShadow: 'none',
                                }}
                                onClick={item.onClick}
                                toggle={toggleItem}
                            >
                                {itemComponent || (
                                    <span style={{ marginLeft: 5 }}>
                                        {item.name}
                                    </span>
                                )}
                            </DropdownItem>
                        );
                    }
                })}
            </DropdownMenu>
        </Dropdown>
    );
}

export default DropdownListButton;
