import React, { useContext } from 'react';
import { calcColumnWidth } from '../../../../utils/UIUtils';
import { TableContext } from '..';
import TComponents from '.';

const Row = props => {
    const { row, onRowClick, isAdditional, allFields } = props;

    const { variant, data, columns, columnsDisabled, tableStyles } = useContext(
        TableContext,
    );

    const bodyStyles = tableStyles?.children.body;

    const rowStyles = bodyStyles.children.row;
    const cellStyles = bodyStyles.children.cell;

    const rowElement = (row, column) => {
        let rowElement = row[(column?.id)];

        if (!!column?.cell) {
            const newRow = { original: row, value: row[column.id] };
            rowElement = column?.cell(newRow, column.id);
        }

        return !rowElement ? ' - ' : rowElement;
    };

    return (
        <tr
            className={`${variant}-row ${rowStyles.className}`}
            style={Object.assign(
                !!onRowClick && { cursor: 'pointer' },
                rowStyles.style,
            )}
            onClick={() => !!onRowClick && onRowClick(row)}
        >
            {columns?.map(column => {
                const { maxWidth, minWidth, headerText } = column.size || {};

                const header = headerText || column.header;
                const additionalOnCellClick = row?.clickHandles?.find(
                    x => x.id === column.id,
                )?.onCellClick;
                const { maxColumnW } = calcColumnWidth(
                    data,
                    column.id,
                    maxWidth,
                    minWidth,
                    header,
                );

                const onCellClick =
                    !isAdditional && column.onCellClick
                        ? column.onCellClick
                        : additionalOnCellClick;
                const hasOnClick =
                    !!column.onCellClick || !!additionalOnCellClick;

                if (
                    !columnsDisabled?.find(x => x === column?.id) &&
                    (allFields || !(isAdditional && !row?.[column.id]))
                )
                    return (
                        <TComponents.Cell
                            key={column?.id}
                            className={`${variant}-cell ${
                                cellStyles.className
                            }`}
                            style={Object.assign(
                                (allFields || !isAdditional) && {
                                    minWidth: maxColumnW,
                                    width: '100%',
                                },
                                isAdditional && {
                                    color: `#000000`,
                                },
                                hasOnClick && {
                                    cursor: 'pointer',
                                },
                                column?.cellStyle || {},
                                cellStyles.style,
                            )}
                            onClick={() =>
                                onCellClick && onCellClick(row, column, columns)
                            }
                        >
                            {rowElement(row, column)}
                        </TComponents.Cell>
                    );
            })}
        </tr>
    );
};

export default Row;
