import * as actionTypes from '../actions/actionTypes';

const initialState = {
    documentsCount: 0,
    documentsCteCount: 0,
    documentsOtherCount: 0,

    documents: [],
    documentsCte: [],
    documentsOther: [],

    loadingNfsList: true,
    loadingCteList: true,
    loadingOtherList: true,

    filtersDocumentType: [],
    documentsReport: [],
    selectedDocument: null,
    validationErrors: [],
    loadingDetails: true,
    loadingListDownloadFile: false,
    loadingModalOrders: false,
    loadingAssociatedDocuments: false,
    loadingdocumentHistory: false,

    ordersDocument: [],
    ordersDocumentCount: 0,

    associatedDocuments: [],
    documentHistory: [],
    associatedDocumentsCount: 0,

    parentChildValues: {},

    loadingDocumentComments: false,
    documentComments: [],
    documentCommentsCount: 0,
};

const setLoadingTrue = (state, action) => {
    return {
        ...state,
        loadingNfsList: true,
        loadingCteList: true,
        loadingOtherList: true,
    };
};

const setLoadingDetailsFalseError = (state, { error }) => {
    return {
        ...state,
        error,
        loadingDetails: false,
    };
};

const setLoadingDetails = (state, action) => {
    return {
        ...state,
        loadingDetails: true,
    };
};

const setDocumentPutRequest = (state, action) => {
    return {
        ...state,
        loadingDetails: true,
    };
};

const setLoadingFalse = (state, action) => {
    return {
        ...state,
        loadingDetails: false,
    };
};

const setTabIndex = (state, action) => {
    return {
        ...state,
        tabIndex: action.tabIndex,
    };
};

//Documents SearchBar
const setDocumentsSearch = (state, action) => {
    return {
        ...state,
        documents: action.documentsSearch,
        documentsCte: action.documentsSearch,
        documentsOther: action.documentsSearch,
        loadingNfsList: false,
        loadingCteList: false,
        loadingOtherList: false,
        loadingListDownloadFile: false,
    };
};

const setDocumentsSearchCount = (state, action) => {
    return {
        ...state,
        documentsCount: action.documentsSearchCount,
        documentsCteCount: action.documentsSearchCount,
        documentsOtherCount: action.documentsSearchCount,
    };
};

//Documents Nfse
const setDocuments = (state, action) => {
    return {
        ...state,
        documents: action.documents,
        loadingNfsList: false,
        loadingListDownloadFile: false,
    };
};

const setDocumentsCount = (state, action) => {
    return {
        ...state,
        documentsCount: action.documentsCount,
    };
};

//Documents Cte
const setDocumentsCte = (state, action) => {
    return {
        ...state,
        documentsCte: action.documentsCte,
        loadingCteList: false,
    };
};

const setDocumentsCteCount = (state, action) => {
    return {
        ...state,
        documentsCteCount: action.documentsCteCount,
    };
};

const setDocumentsCteSearch = (state, action) => {
    return {
        ...state,
        documentsCte: action.documentsCte,
        documentsCteCount: action.documentsCteCount,
        loadingCteList: false,
    };
};

//Documents Other
const setDocumentsOther = (state, action) => {
    return {
        ...state,
        documentsOther: action.documentsOther,
        loadingOtherList: false,
    };
};

const setDocumentsOtherCount = (state, action) => {
    return {
        ...state,
        documentsOtherCount: action.documentsOtherCount,
    };
};

// Others Reducers
const updateFiltersDocumentType = (state, action) => {
    return {
        ...state,
        filtersDocumentType: action.filtersDocumentType,
    };
};

const updateActiveTab = (state, action) => {
    return {
        ...state,
        documentsId: action.documentsId,
        tabIndex: action.tabIndex,
    };
};

const setDocumentsxmlFilesZipRequest = (state, action) => {
    return {
        ...state,
        xmlFilesZip: action.xmlFilesZip,
        loadingListDownloadFile: false,
    };
};

const setDocumentsxmlFilesZip = (state, action) => {
    return {
        ...state,
        loadingListDownloadFile: true,
    };
};

const cleanUpDocumentDetails = (state, action) => {
    return {
        ...state,
        selectedDocument: null,
        associatedDocumentsCount: 0,
        loadingDetails: true,
    };
};

const setDocument = (state, action) => {
    return {
        ...state,
        validationErrors: [],
        selectedDocument: action.document,
        loadingDetails: false,
    };
};

const setDocumentFileUploaded = (state, action) => {
    return {
        ...state,
        loadingDetails: false,
    };
};

const setDocumentsReportRequest = (state, action) => {
    return {
        ...state,
        documentsReport: [],
        loadingNfsList: true,
    };
};

const setDocumentsReport = (state, action) => {
    return {
        ...state,
        documentsReport: action.documentsReport,
        loadingNfsList: false,
    };
};

const setDynamicFormErrors = (state, action) => {
    const error = action.error || {};
    const response = error.response || {};
    const data = response.data || {};
    const errors = data.errors || [];

    return {
        ...state,
        validationErrors: errors,
        loadingDetails: false,
    };
};

const setQuery = (state, { query }) => {
    return {
        ...state,
        query,
        loadingDetails: false,
    };
};

const setOrdersDocument = (state, { ordersDocument }) => {
    return {
        ...state,
        ordersDocument,
        loadingModalOrders: false,
    };
};

const setOrdersDocumentCount = (state, { ordersDocumentCount }) => {
    return {
        ...state,
        ordersDocumentCount,
    };
};

const loadingModalOrdersTrue = (state, action) => {
    return {
        ...state,
        loadingModalOrders: true,
    };
};

const loadingModalOrdersError = (state, { error }) => {
    return {
        ...state,
        error,
        loadingModalOrders: false,
    };
};

const loadingModalOrdersFalse = (state, action) => {
    return {
        ...state,
        loadingModalOrders: false,
    };
};

const setRequestAssociatedDocs = (state, action) => {
    return {
        ...state,
        loadingAssociatedDocuments: true,
        associatedDocuments: [],
    };
};

const setLoadingAssociatedDocsError = (state, { error }) => {
    return {
        ...state,
        error,
        loadingAssociatedDocuments: false,
    };
};

const setAssociatedDocuments = (state, { associatedDocuments }) => {
    return {
        ...state,
        associatedDocuments,
        loadingAssociatedDocuments: false,
    };
};

const setAssociatedDocumentsCount = (state, { associatedDocumentsCount }) => {
    return {
        ...state,
        associatedDocumentsCount,
    };
};

const setParentChildValues = (state, { parentChildValues }) => {
    return {
        ...state,
        parentChildValues: parentChildValues,
    };
};

const cleanValidationErrors = (state, { validationErrors }) => {
    return {
        ...state,
        validationErrors,
    };
};

const setRequestdocumentHistory = (state, action) => {
    return {
        ...state,
        loadingdocumentHistory: true,
        documentHistory: [],
    };
};

const setLoadingdocumentHistoryError = (state, { error }) => {
    return {
        ...state,
        error,
        loadingdocumentHistory: false,
    };
};

const setdocumentHistory = (state, { documentHistory }) => {
    return {
        ...state,
        documentHistory,
        loadingdocumentHistory: false,
    };
};

const setRequestDocumentComments = (state) => {
    return {
        ...state,
        loadingDocumentComments: true,
        documentComments: [],
    };
};

const setLoadingDocumentCommentsRequest = (state) => {
    return {
        ...state,
        loadingDocumentComments: true,
    };
};

const setCreateDocumentCommentResult = (state) => {
    return {
        ...state,
        loadingDocumentComments: false,
    };
};

const setLoadingDocumentCommentsError = (state, { error }) => {
    return {
        ...state,
        error,
        loadingDocumentComments: false,
    };
};

const setDocumentComments = (state, { documentComments, documentCommentsCount }) => {
    return {
        ...state,
        documentComments,
        documentCommentsCount,
        loadingDocumentComments: false,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        //REQUEST
        case actionTypes.INVOICES_OTHER_GET_LIST_REQUEST:
        case actionTypes.INVOICES_CTE_GET_LIST_REQUEST:
        case actionTypes.INVOICES_GET_LIST_REQUEST:
        case actionTypes.INVOICES_SEARCH_GET_LIST_REQUEST:
        case actionTypes.INVOICES_GET_CTE_SEARCH_REQUEST:
            return setLoadingTrue(state, action);

        //Loading Error
        case actionTypes.INVOICE_INFORM_CANCEL_ISSUER_FAILURE:
        case actionTypes.INVOICE_INFORM_CANCEL_ISSUER_SUCCESS:
        case actionTypes.DOCUMENT_RETURN_STEP_SUCCESS:
        case actionTypes.DOCUMENT_RETURN_STEP_FAILURE:
            return setLoadingDetailsFalseError(state, action);

        case actionTypes.DOCUMENT_RETURN_STEP_REQUEST:
        case actionTypes.INVOICE_INFORM_CANCEL_ISSUER_REQUEST:
            return setLoadingDetails(state, action);

        //tabIndex
        case actionTypes.INVOICE_GET_ACTIVE_TAB:
            return setTabIndex(state, action);

        //CleanUp
        case actionTypes.INVOICE_CANCEL_DOCUMENT_REQUEST:
        case actionTypes.INVOICE_GET_DETAILS_REQUEST:
        case actionTypes.NFE_DOCUMENTS_GET_DETAILS_REQUEST:
        case actionTypes.CTE_DOCUMENTS_GET_DETAILS_REQUEST:
            return cleanUpDocumentDetails(state, action);

        //Search
        case actionTypes.INVOICES_SEARCH_GET_LIST_FAILURE:
        case actionTypes.INVOICES_SEARCH_GET_LIST_SUCCESS:
            return setDocumentsSearch(state, action);

        case actionTypes.INVOICES_SEARCH_GET_COUNT_SUCCESS:
            return setDocumentsSearchCount(state, action);

        //NFS
        case actionTypes.INVOICES_GET_LIST_FAILURE:
        case actionTypes.INVOICES_GET_LIST_SUCCESS:
            return setDocuments(state, action);

        case actionTypes.INVOICE_GET_COUNT_SUCCESS:
            return setDocumentsCount(state, action);

        //CTE
        case actionTypes.INVOICES_GET_CTE_SEARCH_SUCCESS:
            return setDocumentsCteSearch(state, action);

        case actionTypes.INVOICES_CTE_GET_LIST_FAILURE:
        case actionTypes.INVOICES_CTE_GET_LIST_SUCCESS:
            return setDocumentsCte(state, action);

        case actionTypes.INVOICE_CTE_GET_COUNT_SUCCESS:
            return setDocumentsCteCount(state, action);

        //OTHER
        case actionTypes.INVOICES_OTHER_GET_LIST_FAILURE:
        case actionTypes.INVOICES_OTHER_GET_LIST_SUCCESS:
            return setDocumentsOther(state, action);

        case actionTypes.INVOICE_OTHER_GET_COUNT_SUCCESS:
            return setDocumentsOtherCount(state, action);

        //REPORT
        case actionTypes.INVOICES_REPORT_GET_LIST_REQUEST:
            return setDocumentsReportRequest(state, action);

        case actionTypes.INVOICES_REPORT_GET_LIST_SUCCESS:
        case actionTypes.INVOICES_REPORT_GET_LIST_FAILURE:
            return setDocumentsReport(state, action);

        //UPLOAD
        case actionTypes.INVOICE_UPLOAD_FILE_SUCCESS:
            return setDocumentFileUploaded(state, action);

        //DETAILS
        case actionTypes.INVOICE_GET_DETAILS_FAILURE:
        case actionTypes.INVOICE_GET_DETAILS_SUCCESS:
            return setDocument(state, action);

        //GET XML
        case actionTypes.INVOICE_GET_XML_ZIP_SUCCESS:
        case actionTypes.INVOICE_GET_XML_ZIP_FAILURE:
            return setDocumentsxmlFilesZipRequest(state, action);

        case actionTypes.INVOICE_GET_XML_ZIP_REQUEST:
            return setDocumentsxmlFilesZip(state, action);

        //PUT
        case actionTypes.INVOICE_PUT_REQUEST:
            return setDocumentPutRequest(state, action);

        case actionTypes.INVOICE_PUT_FAILURE:
            return setDynamicFormErrors(state, action);

        case actionTypes.INVOICE_GET_FILTER_DOCUMENTS_TYPE:
            return updateFiltersDocumentType(state, action);

        case actionTypes.INVOICE_UPDATE_ACTIVE_TAB:
            return updateActiveTab(state, action);

        case actionTypes.QUERY_SET_REQUEST:
            return setQuery(state, action);

        //OrdersDocument
        case actionTypes.DOCUMENT_ORDERS_GET_REQUEST:
        case actionTypes.DOCUMENT_ORDERS_GET_COUNT_REQUEST:
        case actionTypes.DOCUMENT_ORDERS_PUT_REQUEST:
        case actionTypes.DOCUMENT_ORDERS_DELETE_REQUEST:
        case actionTypes.ORDERS_ITEM_GET_LIST_REQUEST:
            return loadingModalOrdersTrue(state, action);

        case actionTypes.DOCUMENT_ORDERS_GET_SUCCESS:
            return setOrdersDocument(state, action);

        case actionTypes.DOCUMENT_ORDERS_GET_COUNT_SUCCESS:
            return setOrdersDocumentCount(state, action);

        case actionTypes.DOCUMENT_ORDERS_PUT_SUCCESS:
        case actionTypes.DOCUMENT_ORDERS_DELETE_SUCCESS:
        case actionTypes.ORDERS_ITEM_GET_LIST_SUCCESS:
            return loadingModalOrdersFalse(state, action);

        case actionTypes.DOCUMENT_ORDERS_GET_FAILURE:
        case actionTypes.DOCUMENT_ORDERS_GET_COUNT_FAILURE:
        case actionTypes.DOCUMENT_ORDERS_PUT_FAILURE:
        case actionTypes.DOCUMENT_ORDERS_DELETE_FAILURE:
            return loadingModalOrdersError(state, action);

        //AssociatedDocuments
        case actionTypes.ASSOCIATED_DOCUMENTS_GET_LIST_REQUEST:
            return setRequestAssociatedDocs(state, action);

        case actionTypes.ASSOCIATED_DOCUMENTS_GET_LIST_FAILURE:
        case actionTypes.ASSOCIATED_DOCUMENTS_GET_COUNT_FAILURE:
        case actionTypes.PARENT_CHILD_VALUES_GET_FAILURE:
            return setLoadingAssociatedDocsError(state, action);

        case actionTypes.ASSOCIATED_DOCUMENTS_GET_LIST_SUCCESS:
            return setAssociatedDocuments(state, action);

        //documentHistory
        case actionTypes.ASSOCIATED_DOCUMENTS_GET_COUNT_SUCCESS:
            return setAssociatedDocumentsCount(state, action);
            
        case actionTypes.TIMELINE_DOCUMENT_GET_LIST_REQUEST:
            return setRequestdocumentHistory(state, action);

        case actionTypes.TIMELINE_DOCUMENT_GET_LIST_SUCCESS:
            return setdocumentHistory(state, action);

        case actionTypes.TIMELINE_DOCUMENT_GET_LIST_FAILURE:
            return setLoadingdocumentHistoryError(state, action);

        //Parent Child Values
        case actionTypes.PARENT_CHILD_VALUES_GET_REQUEST:
        case actionTypes.PARENT_CHILD_VALUES_GET_SUCCESS:
            return setParentChildValues(state, action);

        case actionTypes.INVOICE_PUT_SUCCESS:
            return setLoadingFalse(state, action);

        case actionTypes.CLEAN_VALIDATION_ERRORS:
            return cleanValidationErrors(state, action);

        // Comments
        case actionTypes.DOCUMENT_COMMENTS_GET_LIST_REQUEST:
            return setRequestDocumentComments(state);
            
        case actionTypes.DOCUMENT_COMMENTS_GET_LIST_SUCCESS:
            return setDocumentComments(state, action);
                
        case actionTypes.DOCUMENT_COMMENTS_GET_LIST_FAILURE:
            return setLoadingDocumentCommentsError(state, action);
                    
        case actionTypes.DOCUMENT_COMMENTS_GET_LIST_FAILURE:
            return setLoadingDocumentCommentsError(state, action);

        case actionTypes.DOCUMENT_COMMENTS_POST_REQUEST:
            return setLoadingDocumentCommentsRequest(state);

        case actionTypes.DOCUMENT_COMMENTS_POST_SUCCESS:
        case actionTypes.DOCUMENT_COMMENTS_POST_FAILURE:
            return setCreateDocumentCommentResult(state);

        default:
            return state;
    }
};

export default reducer;
