import React from 'react';
import PropTypes from 'prop-types';

const header = props => {
    const { headerTitleSize, title, subtitle } = props;
    return (
        <React.Fragment>
            <h2
                className='title-page'
                style={headerTitleSize && { fontSize: headerTitleSize }}
            >
                {title}
            </h2>
            {subtitle && <p className='text-muted subtitle-page'>{subtitle}</p>}
        </React.Fragment>
    );
};

header.propTypes = {
    headerTitleSize: PropTypes.bool,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
};

export default header;
