import React from 'react';

function RightArrow({
    width = '24',
    height = '25',
    viewBox = '0 0 24 25',
    fill = 'none',
}) {
    return (
        <svg
            width={width}
            height={height}
            viewBox={viewBox}
            fill={fill}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M10 16.5L14 12.5L10 8.5'
                stroke='black'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}

export default RightArrow;
