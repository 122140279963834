import SuppliersCounterWidget from './SuppliersCounterWidget/SuppliersCounterWidget';
import PendingOrdersCounterWidget from './PendingOrdersCounterWidget/PendingOrdersCounterWidget';
import PendingTransactionsCounterWidget from './PendingTransactionsCounterWidget/PendingTransactionsCounterWidget';
import PendingInvoicesCounterWidget from './PendingInvoicesCounterWidget/PendingInvoicesCounterWidget';
import FinancialVolumeCounterWidget from './FinancialVolumeCounterWidget/FinancialVolumeCounterWidget';
import MonthlyConsumptionCounterWidget from './MonthlyConsumptionCounterWidget/MonthlyConsumptionCounterWidget';
import MonthByMonthVolumeChart from './MonthByMonthVolumeChart/MonthByMonthVolumeChart';
import MonthlyVolumeByClientChart from './MonthlyVolumeByClientChart/MonthlyVolumeByClientChart';
import OverdueTransactionsCounterWidget from './OverdueTransactionsCounterWidget/OverdueTransactionsCounterWidget';
import GenericWidgetTable from './GenericWidgetTable/GenericWidgetTable';
import Widget from './Widget/Widget';

export default {
    SuppliersCounterWidget,
    PendingOrdersCounterWidget,
    PendingTransactionsCounterWidget,
    PendingInvoicesCounterWidget,
    FinancialVolumeCounterWidget,
    MonthlyConsumptionCounterWidget,
    MonthByMonthVolumeChart,
    MonthlyVolumeByClientChart,
    OverdueTransactionsCounterWidget,
    GenericWidgetTable,
    Widget,
};
