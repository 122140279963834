import React from 'react';

const ArrowAsc = ({
    width = '16',
    height = '16',
    viewBox = '0 0 6 9',
    fill = 'none',
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox={viewBox}
            fill={fill}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M0.45178 2.75011C0.378035 2.81244 0.336196 2.89732 0.335467 2.98609C0.334737 3.07486 0.375177 3.16024 0.447891 3.22345C0.520604 3.28666 0.619635 3.32252 0.723197 3.32314C0.826759 3.32377 0.926368 3.28911 1.00011 3.22678L2.46234 1.98678L2.46234 7.95011C2.46234 8.03852 2.50331 8.1233 2.57624 8.18582C2.64917 8.24833 2.74808 8.28345 2.85122 8.28345C2.95436 8.28345 3.05328 8.24833 3.12621 8.18582C3.19914 8.1233 3.24011 8.03852 3.24011 7.95011L3.24011 1.98678L4.679 3.22678C4.71526 3.25764 4.75826 3.28208 4.80553 3.2987C4.85281 3.31531 4.90345 3.32379 4.95454 3.32363C5.00564 3.32348 5.0562 3.3147 5.10334 3.29779C5.15048 3.28089 5.19328 3.25619 5.22928 3.22511C5.26528 3.19403 5.29379 3.15718 5.31318 3.11666C5.33257 3.07613 5.34245 3.03273 5.34227 2.98893C5.34209 2.94514 5.33185 2.9018 5.31213 2.86139C5.29241 2.82099 5.2636 2.78431 5.22734 2.75345L2.85122 0.716779L0.45178 2.75011Z'
                fill='black'
            />
        </svg>
    );
};

export default ArrowAsc;
