import React, { useContext, useEffect } from 'react';
import { TableContext } from '../';
import TComponents from '.';

function Pagination() {
    const { variant, initialState, pagination, tableStyles } = useContext(
        TableContext,
    );

    useEffect(() => onPaginationChange(pagination), [pagination]);

    const paginationStyles = tableStyles?.children.pagination;
    const { onPaginationChange } = initialState;

    return (
        <div
            className={`${variant}-pagination ${paginationStyles?.className}`}
            style={Object.assign(
                {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                },
                paginationStyles?.style,
            )}
        >
            <TComponents.PageSizeSection />
            <TComponents.PageSelector />
        </div>
    );
}

export default Pagination;
