import { useEffect, useState } from 'react';
import { getInitialState, getTableStyles } from './components/constants';
import * as XLSX from 'xlsx';
import { calcColumnWidth } from '../../../utils/UIUtils';

function useTable({
    data,
    count,
    columns,
    loading,
    hasCustomizeColumns,
    exportToXLSX,
    filtersProperties,
    tableStyles: customTableStyles,
    initialState: initialStateTable,
    additionalInfoHeader,
    additionalRows,
    resetPaginationByProps = [],
}) {
    const initialState = getInitialState(initialStateTable);
    const tableStyles = getTableStyles(customTableStyles);

    const [filters, setFilters] = useState(initialState?.filters);
    const [sorting, setSorting] = useState(initialState?.sorting);
    const [pagination, setPagination] = useState(initialState?.pagination);
    const [columnsDisabled, setColumnsDisabled] = useState(
        initialState?.columnsDisabled || [],
    );
    const [minTableSize, setMinTableSize] = useState(0);

    useEffect(() => {
        if (!!resetPaginationByProps) onResetPagination();
    }, [...resetPaginationByProps]);

    useEffect(() => {
        onSetMinTableSize();
    }, [columns, data]);

    const variant = initialState?.variant || 'ui-table';

    const onResetPagination = () => {
        if (pagination) {
            setPagination(prev => ({ ...prev, pageIndex: 0 }));
        } else {
            setPagination({ ...initialState?.pagination, pageIndex: 0 });
        }
    };

    const onSetMinTableSize = () => {
        const columnSizes = columns.map(column => {
            const { maxWidth, minWidth, headerText } = column.size || {};
            const header = headerText || column.header;

            const { maxColumnW } = calcColumnWidth(
                data,
                column.id,
                maxWidth,
                minWidth,
                header,
            );

            return maxColumnW;
        });

        const newTableSize = columnSizes.reduce(
            (tWidth, width) => tWidth + width,
        );

        setMinTableSize(newTableSize);
    };

    const toggleColumn = columnId => {
        if (columnsDisabled?.find(x => x === columnId)) {
            const newColumns = columnsDisabled.filter(x => x !== columnId);
            setColumnsDisabled(newColumns);
        } else {
            const newColumns = [...columnsDisabled, columnId];
            setColumnsDisabled(newColumns);
        }
    };

    const exportTableToXLSX = (data, columns) => {
        const headers = columns.map(col => ({
            label: col.header,
            key: col.id,
        }));

        const tableData = data.map(row => {
            let formattedRow = {};
            headers.forEach(header => {
                formattedRow[header.label] = row[header.key];
            });
            return formattedRow;
        });

        const worksheet = XLSX.utils.json_to_sheet(tableData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        const wscols = headers.map(header => ({
            wpx:
                Math.max(
                    header.label.length,
                    ...tableData.map(
                        row => (`${row[header.label]}` || '').length,
                    ),
                ) * 8,
        }));
        worksheet['!cols'] = wscols;

        const today = new Date();
        const formattedDate = `${String(today.getDate()).padStart(
            2,
            '0',
        )}-${String(today.getMonth() + 1).padStart(
            2,
            '0',
        )}-${today.getFullYear()}`;

        XLSX.writeFile(workbook, `Monitoramento_${formattedDate}.xlsx`);
    };

    return {
        data,
        count,
        columns,
        loading,
        hasCustomizeColumns,
        exportToXLSX,
        initialState,
        tableStyles,
        variant,
        additionalInfoHeader,
        additionalRows,
        filtersProperties,
        // states
        filters,
        setFilters,
        sorting,
        setSorting,
        pagination,
        setPagination,
        columnsDisabled,
        toggleColumn,
        exportTableToXLSX,
        minTableSize,
    };
}

export default useTable;
