import { defineMessages } from "react-intl";
import globalMessages from "../../../../utils/globalMessages";

export default defineMessages({
  ...globalMessages,

  overdueTransactionsCounter: {
    id: "dashboard.overdueTransactionsCounter",
    defaultMessage: "pagamento(s) vencidos"
  }
});
