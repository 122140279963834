import React from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

const tabs = props => {
    const { tabs = [], activeTab, handleTabClick } = props;
    const filteredTabs = tabs?.filter(x => !x.disabled);

    return (
        <div>
            <Nav tabs>
                {filteredTabs.map((data, index) => {
                    return (
                        <NavItem key={index}>
                            <NavLink
                                disabled={data.disabled}
                                className={classnames({
                                    active: activeTab === index,
                                })}
                                onClick={e => handleTabClick(e, index, data)}
                                key={index}
                            >
                                {data.name}
                            </NavLink>
                        </NavItem>
                    );
                })}
            </Nav>
            <TabContent activeTab={activeTab} className='mt-3'>
                {filteredTabs
                    .filter((_, index) => activeTab === index)
                    .map((data, index) => {
                        return (
                            <TabPane
                                tabId={index}
                                key={index}
                                style={{
                                    display: 'block',
                                }}
                            >
                                {data.component}
                            </TabPane>
                        );
                    })}
            </TabContent>
        </div>
    );
};

export default tabs;
