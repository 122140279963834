import { applyMiddleware, combineReducers, compose, createStore } from 'redux';

import { connectRouter, routerMiddleware } from 'connected-react-router';

import thunk from 'redux-thunk';
import globalReducer from './reducers/globalReducer';
import authenticationReducer from './reducers/authenticationReducer';
import contractorReducer from './reducers/contractorReducer';
import supplierReducer from './reducers/supplierReducer';
import orderReducer from './reducers/orderReducer';
import documentReducer from './reducers/documentReducer';
import integrationReducer from './reducers/integrationReducer';
import transactionReducer from './reducers/transactionReducer';
import stateReducer from './reducers/stateReducer';
import mapReducer from './reducers/mapReducer';
import reportReducer from './reducers/reportReducer';
import userReducer from './reducers/userReducer';
import settingsReducer from './reducers/settingsReducer';
import fileReducer from './reducers/fileReducer';
import roleReducer from './reducers/roleReducer';
import claimReducer from './reducers/claimReducer';
import companyReducer from './reducers/companyReducer';
import widgetReducer from './reducers/widgetReducer';
import ocrOperationResultReducer from './reducers/ocrOperationResultReducer';
import logReducer from './reducers/logReducer';
import categoryReducer from './reducers/categoryReducer';
import documentFieldsReducer from './reducers/documentFieldsReducer';
import NFeReducer from './reducers/NFeReducer';
import cteReducer from './reducers/cteReducer';
import pendingFilesReducer from './reducers/pendingFilesReducer';
import healthReducer from './reducers/healthReducer';

export default function configureStore(history, initialState) {
    const reducers = {
        globalReducer,
        authenticationReducer,
        contractorReducer,
        supplierReducer,
        orderReducer,
        documentReducer,
        integrationReducer,
        transactionReducer,
        NFeReducer,
        cteReducer,
        userReducer,
        stateReducer,
        mapReducer,
        settingsReducer,
        fileReducer,
        roleReducer,
        pendingFilesReducer,
        claimReducer,
        companyReducer,
        widgetReducer,
        ocrOperationResultReducer,
        logReducer,
        categoryReducer,
        documentFieldsReducer,
        reportReducer,
        healthReducer,
    };

    const middleware = [thunk, routerMiddleware(history)];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';

    if (
        isDevelopment &&
        typeof window !== 'undefined' &&
        window.__REDUX_DEVTOOLS_EXTENSION__
    ) {
        enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
    }

    const rootReducer = combineReducers({
        ...reducers,
        router: connectRouter(history),
    });

    //Reset redux state only if logoff was successfully
    const combineReducersApp = (state, action) => {
        if (action.type === 'LOG_OUT_SUCCESS') {
            state = {};
        }

        return rootReducer(state, action);
    };

    return createStore(
        combineReducersApp,
        initialState,
        compose(
            applyMiddleware(...middleware, routerMiddleware(history)),
            ...enhancers,
        ),
    );
}
