import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    homeLink: {
        id: 'sidebar.homeLink',
        defaultMessage: 'Início',
    },
    contractorsLink: {
        id: 'sidebar.contractorsLink',
        defaultMessage: 'Contratantes',
    },
    suppliersLink: {
        id: 'sidebar.suppliersLink',
        defaultMessage: 'Fornecedores',
    },
    ordersLink: {
        id: 'sidebar.ordersLink',
        defaultMessage: 'Pedidos',
    },
    integrationsLink: {
        id: 'sidebar.integrationsLink',
        defaultMessage: 'Integrações',
    },
    transactionsLink: {
        id: 'sidebar.transactionsLink',
        defaultMessage: 'Pagamentos',
    },
    mapsLink: {
        id: 'sidebar.mapsLink',
        defaultMessage: 'Mapeamentos',
    },
    reports: {
        id: 'sidebar.reports',
        defaultMessage: 'Relatórios',
    },
    fieldsLink: {
        id: 'sidebar.fieldsLink',
        defaultMessage: 'Campos',
    },
    usersLink: {
        id: 'sidebar.usersLink',
        defaultMessage: 'Usuários',
    },
    settingsLink: {
        id: 'sidebar.settingsLink',
        defaultMessage: 'Configurações',
    },
    logoutLink: {
        id: 'sidebar.logoutLink',
        defaultMessage: 'Sair',
    },
    rolesLink: {
        id: 'sidebar.rolesLink',
        defaultMessage: 'Perfis',
    },
    nfseLink: {
        id: 'sidebar.nfseLink',
        defaultMessage: 'NFS-e',
    },
    nfeLink: {
        id: 'sidebar.nfeLink',
        defaultMessage: 'NF-e',
    },
    cteLink: {
        id: 'sidebar.cteLink',
        defaultMessage: 'CT-e',
    },
    otherLink: {
        id: 'sidebar.otherLink',
        defaultMessage: 'Outros',
    },
    pendingFilesLink: {
        id: 'sidebar.pendingFilesLink',
        defaultMessage: 'Arquivos pendentes',
    },
    healthLink: {
        id: 'sidebar.healthLink',
        defaultMessage: 'Saúde',
    },
});

export default messages;
