import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';

import * as actions from '../../../../store/actions/index';

import { healthPeriod } from '../../../../utils/enums';
import UI from '../../../../components/UI';
import {
    additionalRows,
    columns,
    getFiltersProperties,
    healthInitialState,
    periodOpts,
} from './constants';
import ModalDocumentsStatus from './components/ModalDocumentsStatus';
import { formatFilterForQuery } from '../../../../utils/filtersUtils';
import Loading from '../../../../components/Loading/Loading';

const HealthTable = props => {
    const {
        healthResponse,
        loadingHealthStatus,
        healthDetailsResponse,
        loadingHealthDetails,
        onGetHealthStatus,
        onGetHealthDetailsStatus,
        onCleanHealthDetails,
    } = props;

    const [period, setPeriod] = useState({
        label: 'Dia',
        value: healthPeriod.day,
    });

    const [selectedCellProps, setSelectedCellProps] = useState({});
    const [filtersProperties, setFiltersProperties] = useState([]);
    const [isOpenDocStatus, setIsOpenDocStatus] = useState(false);

    const totalRow = healthResponse?.total;
    const healthData = healthResponse?.citiesInfo;
    const lastUpdate = healthResponse?.lastUpdate;

    useEffect(() => {
        onGetHealthStatus(period.value);
    }, [period]);

    useEffect(() => {
        const newFilterProps = getFiltersProperties(healthData);
        setFiltersProperties(newFilterProps);
    }, [healthResponse?.citiesInfo]);

    useEffect(() => {
        onRedirectToDocuments();
    }, [healthDetailsResponse]);

    useEffect(
        () => () => {
            onCleanHealthDetails();
        },
        [],
    );

    const onRedirectToDocuments = () => {
        const healthFilters = healthDetailsResponse?.filters;
        const columnKey = healthDetailsResponse?.key;
        const isPendingFile = columnKey === 'pendingFile';
        const isWebserviceErrors = columnKey === 'webserviceError';

        const isDocument = !isPendingFile && !isWebserviceErrors;
        const isAdditionalQuery = isWebserviceErrors && '&index=0';

        if (!!healthFilters && isDocument) {
            const filter = formatFilterForQuery(healthFilters);
            window.open(
                `/documents?sort=-createdAt&skip=0&take=10&page=0&${filter}&showChildDocument=true`,
            );
        } else if (isPendingFile || isWebserviceErrors) {
            const path = isPendingFile ? 'pendingFiles' : 'integrations';
            window.open(
                `/${path}${healthFilters}&sort=-createdAt&skip=0&take=10&page=0${isAdditionalQuery}`,
            );
        }
    };

    const onCellClick = (row, column, columns) => {
        onGetHealthDetailsStatus(period.value, column.id, row.ibge);
    };

    const onCellClickInTotal = id => {
        onGetHealthDetailsStatus(period.value, id);
    };

    const onCellModalClick = (row, column, columns) => {
        setSelectedCellProps({
            key: column.id,
            ibgeCode: row.ibge,
            period: period.value,
        });
        setIsOpenDocStatus(prev => !prev);
    };

    const tableColumns = columns(onCellClick, onCellModalClick);

    const formatDate = dateToFormat => {
        const [datePart, timePart] = dateToFormat.split('T');
        const [year, month, day] = datePart.split('-');
        const [hour, minute] = timePart.split(':');

        return `${day}/${month}/${year} às ${hour}:${minute}`;
    };

    const lastUpdateMessage = !!lastUpdate
        ? `Última atualização em: ${formatDate(lastUpdate)}`
        : '';

    return (
        <Container fluid>
            <Loading loading={loadingHealthDetails} />
            <UI.Table
                initialState={healthInitialState}
                loading={loadingHealthStatus}
                columns={tableColumns}
                data={healthData || []}
                additionalRows={additionalRows(
                    totalRow,
                    onCellClickInTotal,
                    onCellClick,
                    onCellModalClick,
                )}
                filtersProperties={filtersProperties}
                additionalInfoHeader={
                    <div className='additional-info-health-status'>
                        <p>{lastUpdateMessage}</p>
                        <UI.Select
                            label={'Periodo'}
                            labelAlign={'left'}
                            options={periodOpts}
                            className='additional-info-select-health-status'
                            onChange={opt => setPeriod(opt)}
                            value={period}
                            noEmptySelect
                        />
                    </div>
                }
                hasCustomizeColumns
                exportToXLSX
                resetPaginationByProps={[period]}
            />
            <ModalDocumentsStatus
                isOpenDocStatus={isOpenDocStatus}
                handleToggleDocStatus={() => setIsOpenDocStatus(prev => !prev)}
                cellProperties={selectedCellProps}
            />
        </Container>
    );
};

const mapStateToProps = state => {
    return {
        healthResponse: state.healthReducer.healthResponse,
        loadingHealthStatus: state.healthReducer.loadingHealthStatus,
        healthDetailsResponse: state.healthReducer.healthDetailsResponse,
        loadingHealthDetails: state.healthReducer.loadingHealthDetails,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetHealthStatus: period => dispatch(actions.getHealthStatus(period)),
        onGetHealthDetailsStatus: (period, key, ibge) =>
            dispatch(actions.getHealthDetailsStatus(period, key, ibge)),
        onCleanHealthDetails: () => dispatch(actions.cleanHealthDetails()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(HealthTable);
