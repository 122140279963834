import React, { useContext } from 'react';
import { TableContext } from '../';
import TComponents from '.';
import { capitalizeWords } from '../../../../utils/stringUtils';
import Icon from '../../../../assets/icons/Index';
import { calcColumnWidth } from '../../../../utils/UIUtils';

function Header(props) {
    const { onSortingChange } = props;

    const {
        variant,
        columns,
        sorting,
        setSorting,
        tableStyles,
        data,
        columnsDisabled,
        minTableSize,
    } = useContext(TableContext);

    const headerStyles = tableStyles?.children.header;
    const headerCellStyles = headerStyles.children.cell;
    const headerRowStyles = headerStyles.children.row;

    const handleSort = column => {
        const isAsc = sorting?.id === column?.id && sorting?.desc;

        if (!!onSortingChange) {
            onSortingChange({ id: column?.id, desc: !isAsc });
        }

        setSorting({ id: column?.id, desc: !isAsc });
    };

    const headerElement = column => {
        let columnElement = capitalizeWords(column?.id);

        if (typeof column?.header === 'string') {
            columnElement = column?.header;
        } else if (typeof column?.header === 'function') {
            columnElement = column?.header();
        }

        return columnElement;
    };

    return (
        <thead
            className={`${variant}-header ${headerStyles.className}`}
            style={Object.assign(
                {
                    minWidth: minTableSize,
                },
                headerStyles.style,
            )}
        >
            <tr
                className={`${variant}-row ${headerRowStyles.className}`}
                style={Object.assign({}, headerRowStyles.style)}
            >
                {columns?.map(column => {
                    const { maxWidth, minWidth, headerText } =
                        column.size || {};
                    const header = headerText || column.header;

                    const { maxColumnW } = calcColumnWidth(
                        data,
                        column.id,
                        maxWidth,
                        minWidth,
                        header,
                    );

                    if (!columnsDisabled?.find(x => x === column?.id))
                        return (
                            <TComponents.Cell
                                key={column?.id}
                                className={`${variant}-cell ${
                                    headerCellStyles.className
                                }`}
                                style={Object.assign(
                                    {
                                        minWidth: maxColumnW,
                                        width: '100%',
                                    },
                                    column?.headerStyle || {},
                                    headerCellStyles.style,
                                )}
                                onClick={() => handleSort(column)}
                                isHeader
                            >
                                <span style={{ paddingRight: 6 }}>
                                    {headerElement(column)}
                                </span>
                                {sorting?.id === column?.id ? (
                                    sorting?.desc ? (
                                        <Icon name='arrowDesc' />
                                    ) : (
                                        <Icon name='arrowAsc' />
                                    )
                                ) : (
                                    <Icon name='arrowDescAsc' />
                                )}
                            </TComponents.Cell>
                        );
                })}
            </tr>
        </thead>
    );
}

export default Header;
