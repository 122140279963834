export const apiErrorCodes = [
    {
        errorCode: 1001,
        id: 'apiErrors.company.documentNumberRequired',
        defaultMessage: 'Número do documento é obrigatório',
    },
    {
        errorCode: 1002,
        id: 'apiErrors.company.companyRequired',
        defaultMessage: 'Nome da empresa é obrigarótio',
    },
    {
        errorCode: 1003,
        id: 'apiErrors.company.displayNameRequired',
        defaultMessage: 'Nome fantasia é obrigatório',
    },
    {
        errorCode: 1004,
        id: 'apiErrors.company.emailRequired',
        defaultMessage: 'Endereço de e-mail é obrigatório',
    },
    {
        errorCode: 1005,
        id: 'apiErrors.company.invalidDocumentNumber',
        defaultMessage: 'Numero do documento inválido',
    },
    {
        errorCode: 1006,
        id: 'apiErrors.company.addressRequired',
        defaultMessage: 'Endereço obrigatório',
    },
    {
        errorCode: 1007,
        id: 'apiErrors.company.cityRequired',
        defaultMessage: 'Nome da cidade é obrigatório',
    },
    {
        errorCode: 1008,
        id: 'apiErrors.company.neighborhoodRequired',
        defaultMessage: 'Bairro é obrigatório',
    },
    {
        errorCode: 1009,
        id: 'apiErrors.company.addressNumberRequired',
        defaultMessage: 'Número do endereço é obrigatório',
    },
    {
        errorCode: 1010,
        id: 'apiErrors.company.zipcodeRequired',
        defaultMessage: 'CEP do endereço é obrigatório',
    },
    {
        errorCode: 1011,
        id: 'apiErrors.company.stateRequired',
        defaultMessage: 'Estado é obrigatório',
    },
    {
        errorCode: 1012,
        id: 'apiErrors.company.streetNameRequired',
        defaultMessage: 'Nome da rua é obrigatório',
    },
    {
        errorCode: 2001,
        id: 'apiErrors.contractor.documentNumberUsed',
        defaultMessage: 'CNPJ em uso por outro contratante',
    },
    {
        errorCode: 2002,
        id: 'apiErrors.contractor.contractorRequired',
        defaultMessage: 'Contratante é obrigatório',
    },
    {
        errorCode: 2003,
        id: 'apiErrors.contractor.contractorNotFound',
        defaultMessage: 'Contratante não encontrado',
    },
    {
        errorCode: 2004,
        id: 'apiErrors.contractor.contractorWrongUser',
        defaultMessage: 'Contratante não pertence ao usuário',
    },
    {
        errorCode: 3000,
        id: 'apiErrors.supplier.documentNumberUsed',
        defaultMessage: 'CPF/CNPJ em uso por outro fornecedor',
    },
    {
        errorCode: 3002,
        id: 'apiErrors.supplier.supplierRequired',
        defaultMessage: 'Fornecedor é obrigatório',
    },
    {
        errorCode: 3003,
        id: 'apiErrors.supplier.supplierNotFound',
        defaultMessage: 'Fornecedor não encontrado ou inativo',
    },
    {
        errorCode: 3004,
        id: 'apiErrors.supplier.supplierWrongUser',
        defaultMessage: 'Fornecedor não pertence ao usuário',
    },
    {
        errorCode: 4001,
        id: 'apiErrors.map.mapNotFoundContractor',
        defaultMessage: 'Mapeamento não encontrado para o contrato',
    },
    {
        errorCode: 4002,
        id: 'apiErrors.map.mapWrongContractor',
        defaultMessage: 'Mapeamento não pertence ao contrato',
    },
    {
        errorCode: 4003,
        id: 'apiErrors.map.duplicatedMap',
        defaultMessage: 'Mapeamento duplicado',
    },
    {
        errorCode: 4004,
        id: 'apiErrors.map.mapNotFound',
        defaultMessage: 'Mapeamento não encontrado',
    },
    {
        errorCode: 4005,
        id: 'apiErrors.map.mapAlreadyExists',
        defaultMessage: 'Mapeamento já existe',
    },
    {
        errorCode: 4006,
        id: 'apiErrors.map.mapCantBeNull',
        defaultMessage: 'Mapeamento não pode ser nulo',
    },
    {
        errorCode: 4007,
        id: 'apiErrors.map.mapRequired',
        defaultMessage: 'Mapeamento é obrigatório',
    },
    {
        errorCode: 30000,
        id: 'apiErrors.map.ocrNotFound',
        defaultMessage: 'Ocr não encontrado',
    },
    {
        errorCode: 31000,
        id: 'apiErrors.map.ocrOperationResultNotFound',
        defaultMessage:
            'Resultado do Ocr não encontrado.Tente Testar OCR antes de executar novamente.',
    },
    {
        errorCode: 4010,
        id: 'apiErrors.map.mapNotFoundForCity',
        defaultMessage:
            'Não foi possível encontrar um mapeamento para a cidade selecionada',
    },
    {
        errorCode: 4011,
        id: 'apiErrors.map.mapDocumentTypeInvalid',
        defaultMessage:
            'O tipo de documento do mapeamento é inválido para ser associado',
    },
    {
        errorCode: 4012,
        id: 'apiErrors.map.boletoMapNotFound',
        defaultMessage: 'Mapeamento de boleto não encontrado',
    },
    {
        errorCode: 4013,
        id: 'apiErrors.map.CreateMapWithSubCategoryWithoutCategory',
        defaultMessage: 'Não é possível criar um mapeamento com subcategoria sem uma categoria associada.',
    },
    {
        errorCode: 5001,
        id: 'apiErrors.transaction.documentCodeRequired',
        defaultMessage: 'Código do documento é obrigatório',
    },
    {
        errorCode: 5002,
        id: 'apiErrors.transaction.documentItemCodeRequired',
        defaultMessage: 'Código do item do  documento é obrigatório',
    },
    {
        errorCode: 5003,
        id: 'apiErrors.transaction.dueDateRequired',
        defaultMessage: 'Data de vencimento é obrigatório',
    },
    {
        errorCode: 5004,
        id: 'apiErrors.transaction.valueGreater',
        defaultMessage: 'Valor da transação deve ser maior que zero',
    },
    {
        errorCode: 5005,
        id: 'apiErrors.transaction.currencyRequired',
        defaultMessage: 'Moeda é obrigatória',
    },
    {
        errorCode: 5006,
        id: 'apiErrors.transaction.transactionNotFound',
        defaultMessage: 'Transação não existe',
    },
    {
        errorCode: 5007,
        id: 'apiErrors.transaction.transactionAlreadyExists',
        defaultMessage: 'Transação já existe',
    },
    {
        errorCode: 5008,
        id: 'apiErrors.transaction.transactionWrongUser',
        defaultMessage: 'Transação não pertence ao usuário',
    },
    {
        errorCode: 6001,
        id: 'apiErrors.order.orderCodeRequired',
        defaultMessage: 'Código do pedido é obrigatório',
    },
    {
        errorCode: 6002,
        id: 'apiErrors.order.orderItemCodeRequired',
        defaultMessage: 'Código do item do pedido é obrigatório',
    },
    {
        errorCode: 6003,
        id: 'apiErrors.order.sheetCodeRequired',
        defaultMessage: 'Código da folha é obrigatório',
    },
    {
        errorCode: 6004,
        id: 'apiErrors.order.sheetItemCodeRequired',
        defaultMessage: 'Código do item da folha é obrigatório',
    },
    {
        errorCode: 6005,
        id: 'apiErrors.order.descriptionRequired',
        defaultMessage: 'Descrição é obrigatória',
    },
    {
        errorCode: 6006,
        id: 'apiErrors.order.amountRequired',
        defaultMessage: 'Quantidade é obrigatória',
    },
    {
        errorCode: 6007,
        id: 'apiErrors.order.measureRequired',
        defaultMessage: 'Unidade de medida é obrigatória',
    },
    {
        errorCode: 6008,
        id: 'apiErrors.order.valueRequired',
        defaultMessage: 'Valor é obrigatório',
    },
    {
        errorCode: 6009,
        id: 'apiErrors.order.currencyRequired',
        defaultMessage: 'Moeda é obrigatória',
    },
    {
        errorCode: 6010,
        id: 'apiErrors.order.orderNotFound',
        defaultMessage: 'Pedido não encontrado',
    },
    {
        errorCode: 6011,
        id: 'apiErrors.order.orderAlreadyExists',
        defaultMessage: 'Pedido já existe',
    },
    {
        errorCode: 6012,
        id: 'apiErrors.order.orderIdRequired',
        defaultMessage: 'ID do pedido é obrigatório',
    },
    {
        errorCode: 6013,
        id: 'apiErrors.order.orderWrongUser',
        defaultMessage: 'Pedido não pertence ao usuário logado',
    },
    {
        errorCode: 6014,
        id: 'apiErrors.order.orderIsRequired',
        defaultMessage:
            'É necessário adicionar pelo menos um item de pedido ao documento',
    },
    {
        errorCode: 6015,
        id: 'apiErrors.order.orderItemIsRequired',
        defaultMessage: 'Item do pedido é obrigatório',
    },
    {
        errorCode: 6016,
        id: 'apiErrors.order.documentNumberIsRequired',
        defaultMessage: 'CNPJ do Tomador é obrigatório',
    },
    {
        errorCode: 6017,
        id: 'apiErrors.order.unitaryNetValueIsRequired',
        defaultMessage: 'É obrigatório informar o valor líquido unitário',
    },
    {
        errorCode: 6018,
        id: 'apiErrors.order.totalNetValueIsRequired',
        defaultMessage: 'É obrigatório informar o valor líquido total',
    },
    {
        errorCode: 6019,
        id: 'apiErrors.order.orderItemNotExists.',
        defaultMessage: 'Order item does not exists.',
    },
    {
        errorCode: 6020,
        id: 'apiErrors.order.orderItemNotFound ',
        defaultMessage: 'Item do Pedido não encontrado',
    },
    {
        errorCode: 6021,
        id: 'apiErrors.order.OrderItemDoesNotBelongToDocumentSupplier ',
        defaultMessage:
            'Item do pedido não pertence ao fornecedor do documento',
    },
    {
        errorCode: 6022,
        id: 'apiErrors.order.OrderItemAlreadyExists ',
        defaultMessage: 'Item do Pedido já existe',
    },
    {
        errorCode: 6023,
        id: 'apiErrors.order.OrderItemIsNotAssociatedToADocument ',
        defaultMessage: 'O item de pedido não está associado ao documento',
    },
    {
        errorCode: 6024,
        id: 'apiErrors.order.NotAllowedToAddMoreThanOneOrderItem ',
        defaultMessage:
            'Não é permitido adicionar mais de um item de pedido. Entre em contato com o time de suporte se você precisa dessa funcionalidade',
    },
    {
        errorCode: 6025,
        id: 'apiErrors.order.unitaryGrossValueIsRequired',
        defaultMessage: 'É obrigatório informar o valor bruto',
    },
    {
        errorCode: 7001,
        id: 'apiErrors.file.fileNotFound',
        defaultMessage: 'Arquivo não encotrado',
    },
    {
        errorCode: 7002,
        id: 'apiErrors.file.fileDeletionFailed',
        defaultMessage: 'Não foi possível deletar o arquivo.',
    },
    {
        errorCode: 7003,
        id: 'apiErrors.file.fileTooBig',
        defaultMessage: 'O arquivo excedeu o limite de 50 megabytes.',
    },
    {
        errorCode: 7004,
        id: 'apiErrors.file.fileUnsupportedImageType',
        defaultMessage: 'Tipo de imagem não suportado.',
    },
    {
        errorCode: 7010,
        id: 'apiErrors.file.fileUnsupportedImageType',
        defaultMessage: 'Tipo de imagem não suportado.',
    },
    {
        errorCode: 7005,
        id: 'apiErrors.file.fileUnsupportedDimensions',
        defaultMessage:
            'As dimensões da imagem deve ser entre 40x40 e 3200x3200.',
    },
    {
        errorCode: 8001,
        id: 'apiErrors.mapItem.mapItemNotFound',
        defaultMessage: 'Item do mapeamento não encontrado',
    },
    {
        errorCode: 8002,
        id: 'apiErrors.mapItem.fileAlreadyExists',
        defaultMessage: 'Arquivo já foi adicionado',
    },
    {
        errorCode: 9001,
        id: 'apiErrors.mapAction.actionNotFound',
        defaultMessage: 'Ação do mapeamento não encontrada',
    },
    {
        errorCode: 10000,
        id: 'apiErrors.document.documentNotFound',
        defaultMessage: 'Documento não encontrado',
    },
    {
        errorCode: 10001,
        id: 'apiErrors.document.documentWrongUser',
        defaultMessage: 'Documento não pertence ao usuário',
    },
    {
        errorCode: 10002,
        id: 'apiErrors.document.updateFilesError',
        defaultMessage: 'Erro ao atualizar campos',
    },
    {
        errorCode: 10003,
        id: 'apiErrors.document.updateFilesErrorInvalidValue',
        defaultMessage:
            'O campo Alíquota deverá conter apenas números, ponto e vírgula',
    },
    {
        errorCode: 10005,
        id: 'apiErrors.document.documentNumberNotFound',
        defaultMessage: 'Erro ao atualizar campos',
    },
    {
        errorCode: 10006,
        id: 'apiErrors.document.documentNumberMatchFailed',
        defaultMessage: 'Document number does not match"',
    },
    {
        errorCode: 10007,
        id: 'apiErrors.document.documentTypeNotAllowedXml',
        defaultMessage: 'Document type not allowed to upload xml',
    },
    {
        errorCode: 10008,
        id: 'apiErrors.document.documentFileGeneratorCte',
        defaultMessage: 'Failed to create cte file',
    },
    {
        errorCode: 11000,
        id: 'apiErrors.user.emailRequired',
        defaultMessage: 'E-mail é obrigatório',
    },
    {
        errorCode: 11001,
        id: 'apiErrors.user.nameRequired',
        defaultMessage: 'Nome é obrigatório',
    },
    {
        errorCode: 11002,
        id: 'apiErrors.user.userAlreadyCreated',
        defaultMessage: 'Usuário já cadastrado',
    },
    {
        errorCode: 11003,
        id: 'apiErrors.user.loggedUserNotFound',
        defaultMessage: 'Usuário logado não encontrado',
    },
    {
        errorCode: 11004,
        id: 'apiErrors.user.userWithoutAccess',
        defaultMessage: 'Usuário não tem acesso a essa funcionalidade',
    },
    {
        errorCode: 11005,
        id: 'apiErrors.user.userNotFound',
        defaultMessage: 'Usuário não encontrado',
    },
    {
        errorCode: 11006,
        id: 'apiErrors.user.updateUserError',
        defaultMessage: 'Erro ao atualizar usuário',
    },
    {
        errorCode: 11007,
        id: 'apiErrors.user.userInvalidCredentials',
        defaultMessage:
            'Usuário/Senha inválidos ou você não tem acesso no sistema',
    },
    {
        errorCode: 11008,
        id: 'apiErrors.user.userInvalidPassword',
        defaultMessage: 'Senha inválida',
    },
    {
        errorCode: 10009,
        id: 'apiErrors.document.documentFileGeneratorNfe',
        defaultMessage: 'Failed to create nfe file',
    },
    {
        errorCode: 11009,
        id: 'apiErrors.user.userPasswordAlreadyUsed',
        defaultMessage: 'Senha atual não pode ser utilizada.',
    },
    {
        errorCode: 11010,
        id: 'apiErrors.user.userInvalidEmail',
        defaultMessage: 'Formato do E-mail inválido.',
    },
    {
        errorCode: 11011,
        id: 'apiErrors.user.UserExistingEmail',
        defaultMessage: 'Este e-mail já está em uso por outro usuário. Por favor, tente um e-mail diferente.',
    },    
    {
        errorCode: 12000,
        id: 'apiErrors.role.roleNotFound',
        defaultMessage: 'Perfil não encontrado',
    },
    {
        errorCode: 12001,
        id: 'apiErrors.role.roleNameRequired',
        defaultMessage: 'Nome do perfil é obrigatório',
    },
    {
        errorCode: 12003,
        id: 'apiErrors.role.roleExists',
        defaultMessage: 'Perfil já cdastrado',
    },

    {
        errorCode: 12004,
        id: 'apiErrors.role.claimNotInformed',
        defaultMessage: 'Nenhuma permissão informada',
    },
    {
        errorCode: 12005,
        id: 'apiErrors.role.claimNotFound',
        defaultMessage: 'Permissão não encontrada',
    },
    {
        errorCode: 12006,
        id: 'apiErrors.role.cannotBeDuplicated',
        defaultMessage: 'Acesso do usuário não pode ser duplicado',
    },
    {
        errorCode: 14001,
        id: 'apiErrors.document.xmlError',
        defaultMessage: 'Erro ao buscar xml',
    },
    {
        errorCode: 14003,
        id: 'apiErrors.xml.nfe.14003',
        defaultMessage: 'O XML enviado não está no padrão de Sefaz',
    },
    {
        errorCode: 15000,
        id: 'apiErrors.document.invalidBeginDate',
        defaultMessage: 'Data de inicio inválida',
    },
    {
        errorCode: 15001,
        id: 'apiErrors.document.invalidEndDate',
        defaultMessage: 'Data final inválida',
    },
    {
        errorCode: 15002,
        id: 'apiErrors.document.invalidinterval',
        defaultMessage: 'Intervalo de datas deve ser inferior a 31 dias',
    },
    {
        errorCode: 15003,
        id: 'apiErrors.document.EmissionDateIsWithinTheLast24hours',
        defaultMessage: 'Documentos de prefeituras integradas com o ALLDOCS só podem ser enviados via e-mail ou pelo portal após 24 horas da emissão',
    },
    {
        errorCode: 16001,
        id:
            'apiErrors.order.cancellationOfTheDocumentHasAlreadyBeenRequested  ',
        defaultMessage: 'O cancelamento do documento já foi solicitado',
    },
    {
        errorCode: 16002,
        id: 'apiErrors.order.requestCancellationDocumentsOlderThan6Months  ',
        defaultMessage:
            'Não é possível solicitar o cancelamento de documentos com mais de 6 meses',
    },
    {
        errorCode: 20000,
        id: 'apiErrors.document.erroGettingImage',
        defaultMessage: 'Erro ao buscar imagem da prefeitura',
    },
    //Category
    {
        errorCode: 20001,
        id: 'apiErrors.category.categoryNotFound',
        defaultMessage: 'Categoria não encontrada.',
    },
    {
        errorCode: 20002,
        id: 'apiErrors.category.categoryAlreadyExists',
        defaultMessage: 'Categoria já existe.',
    },
    {
        errorCode: 20003,
        id: 'apiErrors.category.subCategoryDuplicity',
        defaultMessage: 'Duplicidade de SubCategoria.',
    },
    {
        errorCode: 20004,
        id: 'apiErrors.category.categoryNameIsRequired',
        defaultMessage: 'Nome da categoria é obrigatório.',
    },
    {
        errorCode: 20005,
        id: 'apiErrors.category.categoryCodeIsRequired',
        defaultMessage: 'Código da categoria é obrigatório.',
    },
    {
        errorCode: 20006,
        id: 'apiErrors.category.categoryCodeCantBeZero',
        defaultMessage: 'Código da categoria não pode ser igual a 0.',
    },
    {
        errorCode: 20007,
        id: 'apiErrors.category.categoryCodeAlreadyExists',
        defaultMessage: 'Código de categoria já existe.',
    },
    //Report
    {
        errorCode: 23001,
        id: 'apiErrors.report.reportNotFound',
        defaultMessage: 'Relatório não encontrado',
    },
    {
        errorCode: 23002,
        id: 'apiErrors.report.reportNameRequired',
        defaultMessage: 'Nome de relatório é obrigatório',
    },
    {
        errorCode: 23003,
        id: 'apiErrors.report.reportAlreadyExists',
        defaultMessage: 'Relatório já existe',
    },
    {
        errorCode: 23004,
        id: 'apiErrors.report.reportNotExists',
        defaultMessage: 'Relatório não existe',
    },
    {
        errorCode: 23005,
        id: 'apiErrors.report.reportInvalidStatus',
        defaultMessage: 'Estado inválido',
    },
    {
        errorCode: 24001,
        id: 'apiErrors.mapField.mapFieldNotFound',
        defaultMessage: 'Campo de Mapeamento não encontrado',
    },
    {
        errorCode: 24002,
        id: 'apiErrors.mapField.mapFieldNameRequired',
        defaultMessage: 'Nome do campo de mapeamento é obrigatório',
    },
    {
        errorCode: 24003,
        id: 'apiErrors.mapField.mapFieldAlreadyExists',
        defaultMessage: 'Campo de Mapeamento já existe',
    },
    {
        errorCode: 24004,
        id: 'apiErrors.mapField.mapFieldNotExists',
        defaultMessage: 'Campo de Mapeamento não existe',
    },
    {
        errorCode: 24005,
        id: 'apiErrors.mapField.mapFieldKeyRequired',
        defaultMessage: 'Key do campo de mapeamento é obrigatória',
    },
    {
        errorCode: 24006,
        id: 'apiErrors.company.',
        defaultMessage: 'Tipo de campo de mapeamento inválido',
    },
    {
        errorCode: 25000,
        id: 'apiErrors.company.companyNotExisting',
        defaultMessage: 'Filial não existe',
    },
    {
        errorCode: 25001,
        id: 'apiErrors.company.companyNotFound',
        defaultMessage: 'Filial não encontrada',
    },
    {
        errorCode: 25002,
        id: 'apiErrors.company.certificatePasswordInvalid',
        defaultMessage: 'Senha do certificado inválida',
    },
    {
        errorCode: 25003,
        id: 'apiErrors.company.expiredCertificate',
        defaultMessage: 'O Certificado expirou',
    },
    {
        errorCode: 25004,
        id: 'apiErrors.company.FieldIsRequired',
        defaultMessage: 'É obrigatório informar o certificado',
    },
    {
        errorCode: 26000,
        id: 'apiErrors.nfe.nfeItemNotExists',
        defaultMessage: 'Item de Nfe não existe',
    },
    {
        errorCode: 27000,
        id: 'apiErrors.pendingFile.pendingFileNotFound',
        defaultMessage: 'Arquivo pendente não encontrado'
    },
    {
        errorCode: 27001,
        id: 'apiErrors.pendingFile.statusIsInvalid',
        defaultMessage: 'Status inválido para a ação'
    }
];
