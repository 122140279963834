import React from 'react';

const HealthIcon = ({
    width = '30',
    height = '30',
    viewBox = '0 0 24 24',
    fill = 'none',
    style = '',
}) => (
    <svg
        width={width}
        height={height}
        viewBox={viewBox}
        fill={fill}
        style={style}
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M18 12H16.309C16.12 12 15.946 12.107 15.862 12.276L14.447 15.105C14.263 15.474 13.737 15.474 13.553 15.105L10.43 8.86103C10.249 8.49903 9.735 8.49103 9.543 8.84703L7.984 11.737C7.896 11.899 7.727 12 7.544 12H3'
            stroke='black'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
        <path
            d='M3.52295 9C4.75995 5.507 8.08295 3 11.9999 3C16.9709 3 20.9999 7.029 20.9999 12C20.9999 16.971 16.9709 21 11.9999 21C8.08295 21 4.75995 18.493 3.52295 15'
            stroke='black'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
    </svg>
);

export default HealthIcon;
