import React, { useEffect, useState } from 'react';
import Icon from '../../../assets/icons/Index';

const UIOption = props => {
    const { variant, option, hasSelected, onClick } = props;
    return (
        <div
            key={option.value}
            id={option.value}
            className={`${variant}-option ${hasSelected && 'selected'}`}
            onClick={onClick}
        >
            <span>{option.label}</span>
            {hasSelected && <Icon name='check' />}
        </div>
    );
};

const UISelect = props => {
    const {
        variant = 'ui-select',
        label,
        options = [],
        placeholder,
        noEmptySelect,
        className,
        onChange,
        value,
        labelAlign = 'top',
    } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(value);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const onSelectOption = option => {
        setSelectedOption(option);
        setIsOpen(false);

        onChange(option);
    };

    return (
        <div className={`${variant} ${className}`}>
            <div className={`${variant}-container align-${labelAlign}`}>
                {label && (
                    <label className={`${variant}-label `}>{label}</label>
                )}
                <div
                    className={`${variant}-selected-option`}
                    onClick={toggleDropdown}
                >
                    <span>{selectedOption.label}</span>
                    {isOpen ? (
                        <Icon name='topArrow' />
                    ) : (
                        <Icon name='downArrow' />
                    )}
                </div>
            </div>
            {isOpen && (
                <div className={`${variant}-options-container`}>
                    {!noEmptySelect && (
                        <UIOption
                            option={{
                                value: 'placeholder-key',
                                label: placeholder,
                            }}
                            variant={variant}
                            onClick={() => onSelectOption(placeholder)}
                        />
                    )}
                    {options.map(option => (
                        <UIOption
                            option={option}
                            variant={variant}
                            onClick={() => onSelectOption(option)}
                            hasSelected={option.value === selectedOption.value}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default UISelect;
