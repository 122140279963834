import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,
    errorMessage: {
        id: 'comments.errorMessage',
        defaultMessage: 'O campo de comentário do documento é obrigatório.',
    },
    documentComments: {
        id: 'comments.documentComments',
        defaultMessage: 'Comentários do documento',
    },
    commentLabel: {
        id: 'comments.commentLabel',
        defaultMessage: 'Novo comentário',
    },
    subtitle: {
        id: 'comments.subtitle',
        defaultMessage: ' {length} comentário(s) encontrado(s)',
    },

    //Columns 
    comment: {
        id: 'documentComments.comment',
        defaultMessage: 'Comentário',
    },
    createdBy: {
        id: 'documentComments.createdBy',
        defaultMessage: 'Usuário',
    },
    emailsToNotify: {
        id: 'documentComments.emailsToNotify',
        defaultMessage: 'Email para notificação',
    },
    createdAt: {
        id: 'documentComments.createdAt',
        defaultMessage: 'Criado Em',
    }
});
